import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import ApiUtil from "../api/ApiUtil";


// Thunk for fetching products by search
export const fetchProductsBySearch = createAsyncThunk(
  "product/fetchProductsBySearch",
  async (inputValue, thunkAPI) => {
    try {
      const response = await ApiUtil.SendAsync({
        url: `/product/getBysearch?search=${inputValue}`,
        method: 'GET'
      });
      return response.data.products;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Thunk for fetching all products
export const fetchAllProducts = createAsyncThunk(
  "product/fetchAllProducts",
  async ({ singleCat, currentPage, limit }, thunkAPI) => {
    try {
      const response = await ApiUtil.SendAsync({
        url: "/product/getAll",
        method: 'GET',
        params: {
          cat: singleCat,
          page: currentPage,
          limit: limit,
        }
      });
      return {
        productList: response.data.data,
        total: response.data.total
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Thunk for deleting multiple products
export const deleteManyProducts = createAsyncThunk(
  "product/deleteManyProducts",
  async (productIds, thunkAPI) => {
    try {
      const response = await ApiUtil.SendAsync({
        url: "/product/deleteBulk",
        method: 'DELETE',
        data: {productIds: productIds}
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteSingleProduct = createAsyncThunk(
  "product/deleteSingleProduct",
  async (id, thunkAPI) => {
    try {
      const response = await axios.SendAsync({
        url:`/product/delete/${id}`,
        method: 'DELETE'
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: {
    productList: [],
    total: 0,
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  reducers: {
    setProductList: (state, action) => {
      state.productList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsBySearch.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProductsBySearch.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productList = action.payload
        state.total = action.payload.length
      })
      .addCase(fetchProductsBySearch.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(fetchAllProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.productList = action.payload.productList;
        state.total = action.payload.total
      })
      .addCase(fetchAllProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteSingleProduct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSingleProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        // Optionally, you can remove the deleted product from the state here
        state.productList = state.productList.filter(product => product.id !== action.meta.arg);
      })
      .addCase(deleteSingleProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteManyProducts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteManyProducts.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteManyProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      })
  },
});

export const { setProductList } = productSlice.actions;
export const selectProductList = (state) => state.product;
export default productSlice.reducer;