import React from 'react';
import { Select } from 'antd';

const SearchableDropdown = ({
  label,
  options,
  value,
  onChange,
  error,
  style,
  wrapperClass,
  showSearch = false,
  defaultActiveFirstOption = false,
  optionFilterProp = 'label',
  filterSort,
  placeholder,
  selectClasses,
  variant = 'borderless'
}) => {
  return (
    <div className={`${wrapperClass || ''} d-flex flex-column`}>
      {label && (
        <label className="form-label txt-input">
          {label}
          {error && <span style={{ fontSize: "1.2rem" }} className="text-danger"> *</span>}
        </label>
      )}
      <Select
        showSearch={showSearch}
        defaultActiveFirstOption={defaultActiveFirstOption}
        style={style}
        placeholder={placeholder || (label ? `Select ${label}` : 'Select')}
        optionFilterProp={optionFilterProp}
        filterSort={filterSort}
        options={options}
        className={selectClasses || '' }
        value={value}
        onChange={onChange}
      />
      {error && (!value || (Array.isArray(value) && value.length === 0)) && (
        <p className="text-danger" style={{ fontSize: "14px" }}>
          {error}
        </p>
      )}
    </div>
  );
};

export default SearchableDropdown;
