export const menuItemsColor = [
    {
      id: 1,
     color_name: "Blue",
     color: "Blue",
    },
  
    {
        id: 2,
       color_name: "Gray",
       color: "Gray",
    },
    {
        id: 3,
       color_name: "Brown",
       color: "Brown",
    },
    {
        id: 4,
       color_name: "Beige",
       color: "Beige",
    },
    {
        id: 5,
       color_name: "Powder",
       color: "Powder",
    },
    {
        id: 6,
       color_name: "Stone",
       color: "Stone",
    },
    {
        id: 8,
       color_name: "Charcoal",
       color: "Charcoal",
    },
   
    {
        id: 9,
       color_name: "Emerald",
       color: "Emerald",
    },
    {
        id: 10,
       color_name: "Ink",
       color: "Ink",
    },
    {
        id: 11,
       color_name: "White",
       color: "White",
    },
    {
        id: 12,
       color_name: "Ice",
       color: "Ice",
    },
   
  ];