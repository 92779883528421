import { Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit } from "react-icons/fa";

const rowsPerPage = 10;
const CostEstimationList = () => {
  const [maindata, setMainData] = useState([]);
  const [data, setData] = useState([]);
  const [prodName, setprodName] = useState();
  const [costName, setCostName] = useState();
  const [costId, setCostId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(true);

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "270px",
    },
    {
      name: "Price",
      selector: (row) => row.price,
    },
    {
      name: "Parent Name",
      selector: (row) => row.parentName,
    },

    {
      name: "Actions",
      selector: (row) => (
        <>
          <button
            className="border-0 bg-transparent"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={(e) => {
              setprodName(row._id);
              handleDeleteRow(row._id);
            }}
          >
            <MdDelete className="fs-4" style={{ color: "#dc3545" }} />{" "}
          </button>
          <Link to={`/admin/CostEstimEdit/${row._id}`}>
            <button className="border-0 bg-transparent ">
              <FaEdit className="fs-4" style={{ color: "#198754" }} />
            </button>
          </Link>
        </>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "40px",
        backgroundColor: "white",
        marginTop: "7px",
        boxShadow: " 0px 2px 2px #ced4da",
        transition: "background-color 0.3s",
        "&:hover": {
          backgroundColor: "#f0f0f0",
          cursor: "pointer",
        },
      },
    },

    headCells: {
      style: {
        backgroundColor: "rgb(230, 121, 41)",
        fontSize: "15px",
        color: "white",
        fontWeight: "600",
        boxShadow: "0 2px 2px 0 #ced4da, 0 2px 4px 0 rgba(0, 0, 0, 0.19)",
      },
    },

    cells: {
      style: {},
    },
  };

  useEffect(() => {
    axios
      .get("https://allinonetables.com/api/cost/get")
      .then((res) => {
        console.log(104, res.data);
        setMainData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  const handlePageClick = (id) => {
    axios
      .get(`https://allinonetables.com/api/cost/get/${id}`)
      .then((res) => {
        console.log(104, res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteRow = (id) => {
    console.log(102, id);
    axios
      .delete(`https://allinonetables.com/api/cost/delete/${id}`)
      .then((response) => {
        console.log(29, response);
        setRefresh(!refresh);
      })
      .catch(toast.success("Deleted successfully !"));
  };

  const handlePageClick1 = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentPageData = data.slice(startIndex, endIndex);

  return (
    <>
      <h3 className="mb-4 title ">Cost Estimation List</h3>

      <select
        class="form-select fw-medium form-select mb-3"
        style={{ width: "16rem", backgroundColor: "#dce1e5", color: "black" }}
        aria-label="Large select example"
        onChange={(e) => {
          handlePageClick(e.target.value);
        }}
      >
        <option selected style={{ background: "white" }}>
          Cost Estimation List
        </option>
        {maindata.map((item) => {
          return (
            <>
              <option style={{ background: "white" }} value={item._id}>
                {item.name}
              </option>
            </>
          );
        })}
      </select>
      <DataTable
        columns={columns}
        data={currentPageData}
        customStyles={customStyles}
      />

      <nav aria-label="Page navigation example .d-sm-none .d-md-block">
        <ul className="pagination justify-content-end mt-4">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link "
              style={{
                backgroundColor: "transparent",
                color: "black",
                border: "0",
              }}
              href="#"
              aria-label="Previous"
              onClick={() => handlePageClick1(currentPage - 1)}
            >
              <span
                className=""
                style={{ fontSize: "25px" }}
                aria-hidden="true"
              >
                &laquo;
              </span>
            </button>
          </li>

          {Array.from({ length: 5 }).map((_, index) => {
            const pageNumber = currentPage + index;
            return (
              <li
                key={index}
                className={`page-item ${
                  currentPage === pageNumber ? "active" : ""
                }`}
              >
                <button
                  className="page-link num-click ms-1 mt-2"
                  style={{
                    borderRadius: 60,
                    backgroundColor:
                      currentPage === pageNumber ? "#e67929" : "#f7f6ed",
                    color: currentPage === pageNumber ? "white" : "black",
                    border: "0",
                  }}
                  onClick={() => handlePageClick1(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            );
          })}

          <li
            className={`page-item ${
              currentPage === data.length ? "disabled" : ""
            }`}
          >
            <button
              className="page-link "
              style={{
                backgroundColor: "transparent",
                color: "black",
                border: 0,
                fontSize: "25px",
              }}
              onClick={() => handlePageClick1(currentPage + 1)}
            >
              &raquo;
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default CostEstimationList;
