import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const LoadingButton = (props) =>  {
  return (
    <button
      type={props.type}
      className={`${props.className} ${props.loading ? 'bg-f29a5b': ""}`}
      style={props.style}
      onClick={props.onClick}
    >
      {props.loading ? "Loading..." : <span>{props.children}</span>}
    </button>
  );
}

LoadingButton.propTypes = {
    className: PropTypes.string,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    type: PropTypes.string,
    children: PropTypes.node.isRequired
};
  
  LoadingButton.defaultProps = {
    className: "bg-e67929",
    loading: false,
    type: "button",
    children: 'button'
  };
  
  export default LoadingButton;