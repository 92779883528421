import { Input, Space } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { deleteManyProducts, deleteSingleProduct, fetchAllProducts, fetchProductsBySearch, selectProductList } from "../Slice/productSlice";
import CustomModal from "../components/common/Modal";
import SearchableDropdown from "../components/common/SearchableDropdown";
import useCategories from "../hooks/useCategories";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { debounce } from "lodash";

const ProductList = () => {
  const dispatch = useDispatch();
  const { productList , total: totalProducts } = useSelector(selectProductList);
  const totalRows = totalProducts
  const { simpleCategories } = useCategories();

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isSingleModalOpen, setIsSingleModalOpen] = useState(false);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [forceRefetch, setForceRefetch] = useState(0);

  useEffect(() => {
    dispatch(fetchAllProducts({ selectedCategory, currentPage, limit: perPage }));
  }, [dispatch, currentPage, selectedCategory, forceRefetch, currentPage, perPage]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page)
    setPerPage(newPerPage);
  };

  const handlePageChange = page => {
    if (productList.length < totalProducts) {
      setPerPage(prevPerPage => prevPerPage + 10); // Increase perPage by 10
    }
  };

  const handleClearRows = () => {
    setToggleClearRows(!toggledClearRows);
  }

  const debouncedSearch = useCallback(
    debounce((value) => {
      dispatch(fetchProductsBySearch(value));
    }, 500), // Adjust the delay as needed
    [dispatch]
  );

  const handleSearch = useCallback((e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value) {
      debouncedSearch(value);
    } else {
      dispatch(fetchAllProducts({ selectedCategory, currentPage, limit: perPage }));
    }
  }, [debouncedSearch, dispatch, selectedCategory, currentPage, perPage]);

  const handleCategoryChange = useCallback((value) => {
    setSelectedCategory(value);
  }, []);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleDeleteSingle = useCallback((product) => {
    setProductToDelete(product);
    setIsSingleModalOpen(true);
  }, []);

  const handleDeleteBulk = useCallback(() => {
    setIsBulkModalOpen(true);
  }, []);

  const categoryOptions = useMemo(() => [
    { value: '', label: 'Select Category' },
    ...simpleCategories.map(item => ({ value: item.name, label: item.name }))
  ], [simpleCategories]);

  const handleReset = useCallback(() => {
    setSelectedCategory("");
    setSearchValue("");
    setCurrentPage(1);
    handleClearRows();
    setSelectedRows([]);
    setForceRefetch(prev => prev + 1);
  }, []);

  const columns = [
    {
      name: "Product Name",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.name || row?.item?.name}
        </Link>
      ),
      width: "250px",
    },
    {
      name: "SKU",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.sku || row?.item?.sku}
        </Link>
      ),
    },
    {
      name: "Main Category",
      width: "160px",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.SuperCatName || row?.item?.SuperCatName}
        </Link>
      ),
    },
    {
      name: "Category",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.CatName || row?.item?.CatName}
        </Link>
      ),
    },
    {
      name: "Sub Category",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.SubCatName || row?.item?.SubCatName}
        </Link>
      ),
      width: "160px",
    },
    {
      name: "Brand Name",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.BrandName || row?.item?.BrandName}
        </Link>
      ),
      width: "130px",
    },
    {
      name: "Price",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.price || row?.item?.price}
        </Link>
      ),
    },
    {
      name: "Sale Price",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.sale_price || row?.item?.sale_price}
        </Link>
      ),
    },
    {
      name: "Best Seller",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.BestSeller
            ? "true"
            : "false" || row?.item?.BestSeller
            ? "true"
            : "false"}
        </Link>
      ),
    },
    {
      name: "Box Price",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.BoxPrice || row?.item?.BoxPrice}
        </Link>
      ),
    },
    {
      name: "Box Coverage",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.BoxCoverage || row?.item?.BoxCoverage}
        </Link>
      ),
    },
    {
      name: "Thickness",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.thickness || row?.item?.thickness}
        </Link>
      ),
    },
    {
      name: "Wear Layer Thickness",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.wear_layer_thickness || row?.item?.wear_layer_thickness}
        </Link>
      ),
    },
    {
      name: "Discount",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.isDiscount
            ? "true"
            : "false" || row?.item?.isDiscount
            ? "true"
            : "false"}
        </Link>
      ),
    },
    {
      name: "Review",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.review || row?.item?.review}
        </Link>
      ),
    },
    {
      name: "Ratings",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.ratings || row?.item?.ratings}
        </Link>
      ),
    },
    {
      name: "Most Viewed",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.IsmostViewed
            ? "true"
            : "false" || row?.item?.IsmostViewed
            ? "true"
            : "false"}
        </Link>
      ),
    },
    {
      name: "Width",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.width || row?.item?.width}
        </Link>
      ),
    },
    {
      name: "Length",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug || row?.item?.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.length || row?.item?.length}
        </Link>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <button
          className="border-0 bg-transparent"
          onClick={() => handleDeleteSingle(row)}
        >
          <MdDelete className="fs-4" style={{ color: "#dc3545" }} />
        </button>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "50px",
        backgroundColor: "white",
        marginTop: "7px",
        boxShadow: " 0px 2px 2px #ced4da",
        transition: "background-color 0.3s",
        "&:hover": {
          backgroundColor: "#f0f0f0",
          cursor: "pointer",
        },
      },
    },

    headCells: {
      style: {
        backgroundColor: "rgb(230, 121, 41)",
        fontSize: "15px",
        color: "white",
        fontWeight: "600",
        boxShadow: "0 2px 2px 0 #ced4da, 0 2px 4px 0 rgba(0, 0, 0, 0.19)",
      },
    },

    scrollbar: {
      width: "18px",
      track: {
        backgroundColor: "#f5f5f5",
        borderRadius: "8px", // Customize as needed
      },
      thumb: {
        backgroundColor: "#888",
        borderRadius: "8px", // Customize as needed
      },
    },
  };

  return (
    <>
      <div className="flex justify-between py-4 xs:flex-col sm:flex-row">
        <Space.Compact size="large">
          <Input
            type="text"
            addonBefore={<FaSearch />}
            value={searchValue}
            onChange={handleSearch}
            placeholder="Search Your Product"
          />
          <SearchableDropdown
            showSearch
            value={selectedCategory}
            onChange={handleCategoryChange}
            options={categoryOptions}
            placeholder="Select Category"
          />
        </Space.Compact>
        <Space size="large">
          {selectedRows.length > 0 && (
            <SearchableDropdown
              options={[
                {value:'delete', label: 'delete'}
              ]}
              placeholder="Perform Bulk Actions"
              onChange={handleDeleteBulk}
            />
          )}
          <button
            className="btn btn-primary fw-bold shadow border-0"
            style={{ borderRadius: "10px", backgroundColor: "#e67929" }}
            onClick={handleReset}
          >
            Reset
          </button>
        </Space>
      </div>

      <DataTable
        columns={columns}
        data={productList}
        customStyles={customStyles}
        onSelectedRowsChange={handleRowSelected}
        fixedHeader={true}
        fixedHeaderScrollHeight='700px'
        clearSelectedRows={toggledClearRows}
        selectableRows
        selectableRowsHighlight
        pagination
        paginationServer 
        paginationTotalRows={totalRows} 
        onChangeRowsPerPage={handlePerRowsChange} 
        onChangePage={handlePageChange}
      />

      <CustomModal
        onOk={() => {
          dispatch(deleteSingleProduct(productToDelete._id))
          if (searchValue) {
            dispatch(fetchProductsBySearch(searchValue));
          } else {
            setForceRefetch(prev => prev + 1);
          }
          setIsSingleModalOpen(false);
        }}
        onCancel={() => setIsSingleModalOpen(false)}
        open={isSingleModalOpen}
        title={"Delete Confirmation"}
      >
        <p>Are you sure you want to delete the following item?</p>
        <p>Product: <span className="fw-bold">{productToDelete?.name}</span></p>
      </CustomModal>

      <CustomModal
        onOk={() => {
          dispatch(deleteManyProducts(selectedRows.map(row => row._id)))
          .unwrap()
          .then(() => {
            toast.success(`Successfully deleted ${selectedRows.length} product(s)`);
            if (searchValue) {
              dispatch(fetchProductsBySearch(searchValue));
            } else {
              setForceRefetch(prev => prev + 1);
            }
            setIsBulkModalOpen(false);
            setSelectedRows([]);
          })
          .catch((error) => {
            toast.error(`Error deleting products: ${error.message}`);
          });
        }}
        onCancel={() => setIsBulkModalOpen(false)}
        open={isBulkModalOpen}
        title={"Delete Confirmation"}
      >
        <div>
          <p className="fw-bold">Total selected items: {selectedRows.length}</p>
          <p>Are you sure you want to delete following items?</p>
          <ul>
            {selectedRows.map((x, index) => (
              <li key={index}>{x.name}</li>
            ))}
          </ul>
        </div>
      </CustomModal>
    </>
  );
};

export default ProductList;
