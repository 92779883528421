import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const OrderViewPage = () => {
  const { id } = useParams();
  const [orderData, setOrderData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    axios
      .get(`https://allinonetables.com/api/order/${id}`)
      .then((res) => {
        const response = res.data;

        console.log(15, response);
        setOrderData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, refresh]);

  function formatPrice(price) {
    if (typeof price !== "number" || isNaN(price)) {
      return "Invalid Price";
    }
    const formattedPrice = price.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedPrice;
  }

  return (
    <>
      <h3 className="mb-4 title ">Product Order </h3>

      <form
        class="row g-3 pt-3 shadow p-3 mb-5"
        style={{ padding: 20, backgroundColor: "#f7f6ed" }}
      >
        <h5 className="fw-bold">Personal Details </h5>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label txt-input">
           Order Id:
          </label>{" "}
          &nbsp; {orderData[0]?.orderId}
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label txt-input">
            First Name :
          </label>{" "}
          &nbsp; {orderData[0]?.firstName}
        </div>

        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
            Last Name :
          </label>{" "}
          &nbsp; {orderData[0]?.lastName}
        </div>

        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
            Email :
          </label>{" "}
          &nbsp; {orderData[0]?.email}
        </div>

        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
            City :
          </label>{" "}
          &nbsp; {orderData[0]?.city}
        </div>

        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
            Mobile No. :
          </label>{" "}
          &nbsp; {orderData[0]?.mNo}
        </div>

        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
            Postal Code :
          </label>{" "}
          &nbsp; {orderData[0]?.postalCode}
        </div>

        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
            Total Price :
          </label>{" "}
          &nbsp;$ {formatPrice(orderData[0]?.totalPrice)} CAD
        </div>
        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
            Payment Status :
          </label>{" "}
          &nbsp; {orderData[0]?.paymentStatus ? "DONE" : "PENDING"}
        </div>
        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
          City :
          </label>{" "}
          &nbsp; {orderData[0]?.city}
        </div>
        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
            Address :
          </label>{" "}
          &nbsp; {orderData[0]?.address}
        </div>
        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
          Apartment :
          </label>{" "}
          &nbsp; {orderData[0]?.Apartment}
        </div>
        <div class="col-md-6">
          <label for="formFile" class="form-label txt-input">
          Company :
          </label>{" "}
          &nbsp; {orderData[0]?.company}
        </div>


        <h4 className="fw-bold text-center">Order Item</h4>

        {orderData[0]?.orderItems.map((item, index) => {
          return (
            <>
              <div className="border order-card bg-white">
                <h5 className="pt-3 fw-bold">{index + 1}. Product Details</h5>
                <div className="row pt-2">
                  <div class="col-md-6">
                    <label for="formFile" class="form-label txt-input">
                      Product Name :
                    </label>{" "}
                    &nbsp; {item.ProductName}
                  </div>

                  <div class="col-md-6">
                    <label for="formFile" class="form-label txt-input">
                      SKU :
                    </label>{" "}
                    &nbsp; {item.SKU}
                  </div>

                  <div class="col-md-6">
                    <label for="formFile" class="form-label txt-input">
                      Box Coverage :
                    </label>{" "}
                    &nbsp; {formatPrice(item.boxCovrage)}
                  </div>

                  <div class="col-md-6">
                    <label for="formFile" class="form-label txt-input">
                      Box Quantity :
                    </label>{" "}
                    &nbsp; {item.boxQuantity}
                  </div>

                  <div class="col-md-6">
                    <label for="formFile" class="form-label txt-input">
                      Box Price :
                    </label>{" "}
                    &nbsp; $ {formatPrice(item.perBoxPrice)} CAD
                  </div>
                  <div class="col-md-6">
                    <label for="formFile" class="form-label txt-input">
                      is Werehouse Pickup :
                    </label>{" "}
                    &nbsp; {item.is_werehousePickup ? "Yes" : "No"}
                  </div>

                  {
                    item.is_werehousePickup ?(<>
                  <h4 className="fw-bold text-center">Pickup Information </h4>

                  <div class="col-md-6">
                    <label for="formFile" class="form-label txt-input">
                      First and Last Name:
                    </label>{" "}
                    &nbsp; {item?.pickup_firstName}      {item?.pickup_lastName}
                  </div>
                  <div class="col-md-6">
                    <label for="formFile" class="form-label txt-input">
                      Pickup Address:
                    </label>{" "}
                    &nbsp; {item?.WerehouseAddress}
                  </div>
                  <div class="col-md-6">
                    <label for="formFile" class="form-label txt-input">
                      Pickup Email Reciver:
                    </label>{" "}
                    &nbsp; {item?.pickup_email}
                  </div>
                  <div class="col-md-6">
                    <label for="formFile" class="form-label txt-input">
                      Pickup Date :
                    </label>{" "}
                    &nbsp; {item?.pickup_Date}
                  </div>
                    
                    </>):(<>
                    
                    </>)
                  }

                  {item.room.map((room, index) => {
                    return (
                      <>
                        {room.length !== 0 && (
                          <>
                            <h5 className="pt-4 mb-3 fw-bold ">Room Details</h5>
                            <div class="col-md-6">
                              <label
                                for="formFile"
                                class="form-label txt-input"
                              >
                                Room length :
                              </label>{" "}
                              &nbsp; {room.roomLength}
                            </div>

                            <div class="col-md-6">
                              <label
                                for="formFile"
                                class="form-label txt-input"
                              >
                                Room Type :
                              </label>{" "}
                              &nbsp; {room.roomType}
                            </div>

                            <div class="col-md-6">
                              <label
                                for="formFile"
                                class="form-label txt-input"
                              >
                                Room Width :
                              </label>{" "}
                              &nbsp; {room.roomWidth}
                            </div>

                            <div class="col-md-6">
                              <label
                                for="formFile"
                                class="form-label txt-input"
                              >
                                Total Sq.ft. :
                              </label>{" "}
                              &nbsp; {formatPrice(room.totalsqft)}
                            </div>

                            <hr
                              className="mt-3"
                              style={{
                                height: "2px",
                                borderWidth: 0,
                                color: "gray",
                                backgroundColor: "lightgray",
                                width: "93%",
                                marginLeft: "30px",
                              }}
                            ></hr>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          );
        })}

        <div class="col-md-12 text-center " style={{ marginTop: "30px" }}>
          <Link
            to={"/admin/Order"}
            type="submit"
            class="btn btn-primary fw-bold shadow border-0 p-2 mx-3"
            style={{ backgroundColor: "#e67929", width: 100 }}
          >
            Back{" "}
          </Link>
        </div>
      </form>
    </>
  );
};

export default OrderViewPage;
