import React from "react";

const DynamicFields = ({
  allFiltersValues,
  af,
  addFilterHandler,
  removeFilterHandler,
}) => {
  return (
    <div className="d-flex flex-row justify-content-start align-items-end mt-2">
      <div>
        <label class="form-label txt-input">
          Add Filter <span className="text-danger"></span>
        </label>
        <br></br>
        <select
          name=""
          id=""
          style={{
            width: "400px",
            backgroundColor: "#dce1e5",
            color: "black",
          }}
          className="btn btn-secondary border-0"
          value={
            af && af.name
              ? allFiltersValues.findIndex((fv) => fv.name === af.name)
              : ""
          }
          onChange={(e) => {
            addFilterHandler(allFiltersValues[e.target.value], e.target.value);
          }}
        >
          <option style={{ background: "white", color: "black" }} value="">
            {af && af.name ? af.name : "-- Select Filter --"}
          </option>
          {allFiltersValues.map((fv, index) => {
            return (
              <option
                key={index}
                style={{
                  background: "white",
                  color: "black",
                  textAlign: "left",
                }}
                value={index}
              >
                {fv.name}
              </option>
            );
          })}
        </select>
      </div>

      <div
        class="btn btn-primary fw-bold shadow border-0 ms-2"
        style={{ backgroundColor: "#e67929", width: 150, height: 40 }}
        onClick={removeFilterHandler}
      >
        Remove
      </div>
    </div>
  );
};

export default DynamicFields;
