import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Resetpassword from "./pages/Resetpassword";
import Forgotpassword from "./pages/Forgotpassword";
import MainLayout from "./components/MainLayout";
import Brand from "./pages/Brand";
import Category from "./pages/Category";
import MainCatEdit from "./pages/MainCatEdit";
import ProductList from "./pages/ProductList";
import Products from "./pages/Products";
import CostEstimation from "./pages/CostEstimation";
import Order from "./pages/Order";
import ProductViewPage from "./pages/ProductViewPage";
import OrderViewPage from "./pages/OrderViewPage";
import CostEstimationList from "./pages/CostEstimationList";
import CostEstimationOrder from "./pages/CostEstimationOrder";
import CostEstiOrderView from "./pages/CostEstiOrderView";
import CostEstimEdit from "./pages/CostEstimEdit";
import BrandList from "./pages/BrandList";
import InstallationOrder from "./pages/InstallationOrder";
import CategoryList from "./pages/CategoryList";
import CatEdit from "./pages/CatEdit";
import SubCatEdit from "./pages/SubCatEdit";
import Werehouse from "./pages/Werehouse";
import WerehouseList from "./pages/WerehouseList";
import Banner from "./pages/Banner";
import BannerList from "./pages/BannerList";
import Filter from "./pages/Filter";
import FilterList from "./pages/FilterList";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/reset-password" element={<Resetpassword />} />
        <Route path="/forgot-password" element={<Forgotpassword />} />
        <Route path="/admin" element={<MainLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="/admin/Products" element={<Products />} />
          <Route path="/admin/Products/:id" element={<Products />} />
          <Route path="/admin/ProductViewPage" element={<ProductViewPage />} />
          <Route path="/admin/OrderViewPage/:id" element={<OrderViewPage />} />
          <Route
            path="/admin/ProductViewPage/:id"
            element={<ProductViewPage />}
          />
          <Route path="/admin/ProductList" element={<ProductList />} />
          <Route path="/admin/category" element={<Category />} />
          <Route path="/admin/SubCatEdit" element={<SubCatEdit />} />
          <Route path="/admin/SubCatEdit/:id" element={<SubCatEdit />} />
          <Route path="/admin/CatEdit" element={<CatEdit />} />
          <Route path="/admin/CatEdit/:id" element={<CatEdit />} />
          <Route path="/admin/MainCatEdit" element={<MainCatEdit />} />
          <Route path="/admin/MainCatEdit/:id" element={<MainCatEdit />} />
          <Route path="/admin/categoryList" element={<CategoryList />} />
          <Route path="/admin/brand" element={<Brand />} />
          <Route path="/admin/BrandList" element={<BrandList />} />
          <Route path="/admin/BrandList/:id" element={<BrandList />} />
          <Route path="/admin/brand/:id" element={<Brand />} />
          <Route path="/admin/costEstimation" element={<CostEstimation />} />
          <Route
            path="/admin/CostEstimationList"
            element={<CostEstimationList />}
          />
          <Route
            path="/admin/CostEstimationList/:id"
            element={<CostEstimationList />}
          />
          <Route
            path="/admin/CostEstimationOrder"
            element={<CostEstimationOrder />}
          />
          <Route
            path="/admin/CostEstimationOrder"
            element={<CostEstimationOrder />}
          />
          <Route
            path="/admin/CostEstiOrderView/:id"
            element={<CostEstiOrderView />}
          />
          <Route path="/admin/CostEstimEdit" element={<CostEstimEdit />} />
          <Route path="/admin/CostEstimEdit/:id" element={<CostEstimEdit />} />
          <Route path="/admin/order" element={<Order />} />
          <Route path="/admin/order/:id" element={<Order />} />
          <Route path="/admin/wherehouse" element={<Werehouse />} />
          <Route path="/admin/wherehouse/:id" element={<Werehouse />} />
          <Route path="/admin/wherehouselist" element={<WerehouseList />} />
          <Route path="/admin/Banner" element={<Banner />} />
          <Route path="/admin/Banner/:id" element={<Banner />} />
          <Route path="/admin/BannerList" element={<BannerList />} />
          <Route
            path="/admin/installationOrder"
            element={<InstallationOrder />}
          />
          <Route path="/admin/Filter" element={<Filter />} />
          <Route path="/admin/Filter/:id" element={<Filter />} />
          <Route path="/admin/FilterList" element={<FilterList />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
