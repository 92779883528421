import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiUtil from "../api/ApiUtil";

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async () => {
    const response = await ApiUtil.SendAsync({
      url:"/category/get",
      method: "GET"
    });
    return response.data.categories;
  }
);

const categorySlice = createSlice({
  name: "categories",
  initialState: {
    categories: [],
    superCategories: [],
    subCategories: [],
    simpleCategories: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
        state.superCategories = action.payload.filter((item) => item.Type === "Super_Cat");
        state.subCategories = action.payload.filter((item) => item.Type === "Sub_cat");
        state.simpleCategories = action.payload.filter((item) => item.Type === "Cat");
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectCategories = (state) => state.categories.categories;
export const selectSuperCategories = (state) => state.categories.superCategories;
export const selectSubCategories = (state) => state.categories.subCategories;
export const selectSimpleCategories = (state) => state.categories.simpleCategories;


export default categorySlice.reducer;
