import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from '../Slice/dashboardSlice';
import productReducer from '../Slice/productSlice';
import brandSlice from '../Slice/brandSlice';
import installationReducer from '../Slice/installationSlice';
import categorySlice from '../Slice/categorySlice';

const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    product: productReducer,
    brand: brandSlice,
    installation: installationReducer,
    categories: categorySlice
  },
});

export default store;
