import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import ApiUtil from "../api/ApiUtil";
import { toast } from "react-toastify";

const FilterList = () => {
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingRows, setLoadingRows] = useState({});
  const navigate = useNavigate();


  const updateFilters = async (catId) => {
    debugger
    setLoadingRows((prevState) => ({ ...prevState, [catId]: true })); // Set loading for the specific row
    try {
      const response = await ApiUtil.SendAsync({
        url: `/filter/update/${catId}`,
        method: "PUT",
      });
      debugger
      if (response.status === 200) {
        toast.success('Filter Updated')
      } else {
        toast.success('Error Updating Filters')
      }
    } catch (error) {
      toast.error(`Error updating filters: ${error}`);
    } finally {
      setLoadingRows((prevState) => ({ ...prevState, [catId]: false }));
    }
  };
console.log('loadingRows', loadingRows)
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const response = await fetch("https://allinonetables.com/api/filter");
        const result = await response.json();

        if (result.statusCode === 200) {
          setFilters(result.data.filters);
        } else {
          console.error("Failed to fetch filters");
        }
      } catch (error) {
        console.error("Error fetching filters:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFilters();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Category ID",
      selector: (row) => row.categoryId,
      sortable: true,
    },
    {
      name: "Total Filters",
      selector: (row) => row.totalFilters,
      sortable: true,
    },
    {
      name: "Edit Filter",
      cell: (row) => (
        <button
          onClick={() => navigate(`/admin/Filter/${row._id}`)}
          className="btn btn-primary fw-bold shadow border-0"
          style={{ borderRadius: "10px", backgroundColor: "#e67929" }}
        >
          Edit
        </button>
      ),
    },
    {
      name: "Update Filter",
      cell: (row) => (
        <button
          onClick={() => updateFilters(row.categoryId)}
          className="btn btn-primary fw-bold shadow border-0"
          style={{ borderRadius: "10px", backgroundColor: "#e67929" }}
          disabled={!!loadingRows[row.categoryId]} // Disable while the row is loading
        >
          {loadingRows[row.categoryId] ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          ) : (
            "Update"
          )}
        </button>
      ),
    },
  ];

  return (
    <div>
      <h1>Filters</h1>
      <DataTable
        title="Filters List"
        columns={columns}
        data={filters}
        progressPending={loading}
        pagination
        highlightOnHover
        pointerOnHover
      />
    </div>
  );
};

export default FilterList;
