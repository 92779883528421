import * as XLSX from 'xlsx';
import Papa from 'papaparse'

export const parseXlsxFile = (file) => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsArrayBuffer(file);
		fileReader.onload = (e) => {
			const bufferArray = e.target.result;
			const workbook = XLSX.read(bufferArray, { type: "buffer" });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(worksheet);
			resolve(jsonData);
		};
		fileReader.onerror = (error) => {
			reject(error);
		};
	});
};

export const getFileExtension = (file) => {
  const fileType = file.name.split('.').pop();
	return fileType;
}

export const parseCsvFile = async (file) => {
	return new Promise((resolve, reject) => {
		Papa.parse(file, {
			header: true, // Assuming the CSV has headers
			complete: (results) => {
				resolve(results.data);
			},
			error: (error) => {
				reject(error);
			}
		});
	});
};