import axios from "axios";

const BASEURL = "https://allinonetables.com/api";

console.log('BASEURL', BASEURL)
const AppInstance = axios.create({
	baseURL: BASEURL,
});
const cancelTokenSource = axios.CancelToken.source();

AppInstance.interceptors.request.use(
	(request) => {
		request.headers["Content-Type"] = "application/json;charset=UTF-8";
		request.headers["Accept"] = "application/json";
		request.cancelToken = cancelTokenSource.token;

		return request;
	},
	(error) => {
		console.log("Error axios interceptor", error);
		throw error;
	}
);

AppInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (axios.isCancel(error)) {
			console.log("Request canceled:", error.message);
		} else {
			throw error;
		}
	}
);

export default AppInstance;