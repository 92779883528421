import React, { useEffect, useState } from "react";
import axios from "axios";
import DynamicFields from "../components/common/Filter/DynamicFields";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const Filter = () => {
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    categoryId: "",
  });
  const [formDataError, setFormDataError] = useState(null);
  const [addFilters, setAddFilters] = useState([
    {
      name: "",
      type: "",
      filter_param: "",
      query_param: "",
    },
  ]);
  const { id } = useParams();

  const [allFiltersValues, setAllFiltersValues] = useState([
    {
      name: "brand",
      type: "checkbox",
      filter_param: "BrandName",
      query_param: "BrandName",
    },
    {
      name: "Category",
      type: "checkbox",
      filter_param: "CatName",
      query_param: "CatName",
    },
    {
      name: "Color",
      type: "checkbox",
      filter_param: "color",
      query_param: "color",
    },
    {
      name: "Species",
      type: "checkbox",
      filter_param: "species",
      query_param: "species",
    },
    {
      name: "Thickness",
      type: "checkbox",
      filter_param: "thickness",
      query_param: "thickness",
    },
    {
      name: "Finish",
      type: "checkbox",
      filter_param: "Finish",
      query_param: "Finish",
    },
  ]);

  useEffect(() => {
    axios
      .get(`https://allinonetables.com/api/filter/id/${id}`)
      .then((res) => {
        const response = res.data.data;
        console.log(response);
        setFormData({ name: response.name, categoryId: response.categoryId });
        setAddFilters([...response.allFilters, addFilters]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const removeFilterHandler = (index) => {
    if (addFilters.length === index + 1) {
      return;
    }
    const allAddedFilters = addFilters.filter((_, i) => i !== index);
    setAddFilters(allAddedFilters);
  };

  const addFilterHandler = (selectedFilter, selectedIndex) => {
    const checkFilter = addFilters.some((f) => f.name === selectedFilter.name);
    if (checkFilter) {
      return;
    }
    setAddFilters([selectedFilter, ...addFilters]);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const onDataSaveHandler = (e) => {
    e.preventDefault();

    if (!formData.name) {
      setFormDataError("Select a Filter Category before submitting the form.");
      return;
    }

    const filtersWithValues = addFilters.filter(
      (af) => af.name && af.name.trim() !== ""
    );

    const data = {
      name: formData.name,
      categoryId: formData.categoryId,
      allFilters: filtersWithValues,
    };

    if (id) {
      axios
        .put(`https://allinonetables.com/api/filter/${id}`, data)
        .then((res) => {
          const response = res.data;
          toast.success("Successfully updated !");
        })
        .catch((err) => {
          console.log("err : ", err);
        });
    } else {
      axios
        .post("https://allinonetables.com/api/filter/create", data)
        .then((res) => {
          const response = res.data;
          toast.success("Successfully Added !");
        })
        .catch((err) => {
          console.log("err : ", err);
        });
    }
  };

  const updateAllFilters = (e) => {
    e.preventDefault();

    if (!formData.name) {
      setFormDataError("Select a Filter Category before submitting the form.");
      return;
    }

    axios
      .put(
        `https://allinonetables.com/api/filter/update/${formData.categoryId}`
      )
      .then((res) => {
        const response = res.data;
        toast.success("Successfully Updated !");
      })
      .catch((err) => {
        console.log("err : ", err);
      });
  };

  const getCategories = async () => {
    await axios
      .get("https://allinonetables.com/api/category/get")
      .then((res) => {
        const response = res.data.categories;
        setCategories(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h3 className="mb-4 title">Add New Filter</h3>
      <form
        class="row g-3 pt-3 shadow p-3 mb-5"
        style={{ padding: 20, backgroundColor: "#f7f6ed" }}
      >
        {formDataError && (
          <div style={{ fontSize: "20px", color: "red" }}>{formDataError}</div>
        )}
        <div class="col-md-6">
          <label class="form-label txt-input">
            Select Category <span className="text-danger"></span>
          </label>
          <br></br>
          <select
            name=""
            id=""
            style={{
              width: "250px",
              backgroundColor: "#dce1e5",
              color: "black",
            }}
            className="btn btn-secondary border-0"
            onChange={(e) => {
              const [id, name] = e.target.value.split(",");
              setFormData({
                ...formData,
                name: name,
                categoryId: id,
              });
              setFormDataError(null);
            }}
            disabled={id}
          >
            <option style={{ background: "white", color: "black" }} value="">
              {formData.name && formData.name
                ? formData.name
                : "-- Select Category --"}
            </option>
            {categories.map((category) => {
              return (
                <option
                  key={category._id}
                  style={{
                    background: "white",
                    color: "black",
                    textAlign: "left",
                  }}
                  value={[category._id, category.name]}
                >
                  {category.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="col-sm-12">
          {addFilters.map((af, index) => {
            return (
              <DynamicFields
                key={index}
                allFiltersValues={allFiltersValues}
                af={af}
                addFilterHandler={addFilterHandler}
                removeFilterHandler={() => removeFilterHandler(index)}
              />
            );
          })}
        </div>
        {id ? (
          <div class="col-md-12 " style={{ marginTop: 20 }}>
            <button
              class="btn btn-primary fw-bold shadow border-0"
              type="submit"
              style={{ backgroundColor: "#e67929", width: 150, height: 40 }}
              onClick={(e) => onDataSaveHandler(e)}
            >
              Update Filter
            </button>
          </div>
        ) : (
          <div class="col-md-12 " style={{ marginTop: 20 }}>
            <button
              class="btn btn-primary fw-bold shadow border-0"
              type="submit"
              style={{ backgroundColor: "#e67929", width: 150, height: 40 }}
              onClick={(e) => onDataSaveHandler(e)}
            >
              Add New Filter
            </button>
          </div>
        )}
      </form>
      <form
        class="row g-3 pt-3 shadow p-3 mb-5"
        style={{ padding: 20, backgroundColor: "#f7f6ed" }}
      >
        {formDataError && (
          <div style={{ fontSize: "20px", color: "red" }}>{formDataError}</div>
        )}
        <div class="col-md-6">
          <label class="form-label txt-input">
            Auto add filters values <span className="text-danger"></span>
          </label>
          <br></br>
          <select
            name=""
            id=""
            style={{
              width: "250px",
              backgroundColor: "#dce1e5",
              color: "black",
            }}
            className="btn btn-secondary border-0"
            onChange={(e) => {
              const [id, name] = e.target.value.split(",");
              setFormData({
                ...formData,
                name: name,
                categoryId: id,
              });
              setFormDataError(null);
            }}
          >
            <option style={{ background: "white", color: "black" }} value="">
              -- Selecte Category
            </option>
            {categories.map((category) => {
              return (
                <option
                  key={category._id}
                  style={{
                    background: "white",
                    color: "black",
                    textAlign: "left",
                  }}
                  value={[category._id, category.name]}
                >
                  {category.name}
                </option>
              );
            })}
          </select>
        </div>

        <div class="col-md-12 " style={{ marginTop: 20 }}>
          <button
            class="btn btn-primary fw-bold shadow border-0"
            type="submit"
            style={{ backgroundColor: "#e67929", width: 250, height: 40 }}
            onClick={(e) => updateAllFilters(e)}
          >
            Update Filter Values
          </button>
        </div>
      </form>
    </div>
  );
};

export default Filter;
