import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    productValue: 1, // Set your initial value here
    totalOrders: 0,
  },
  reducers: {
    incrementProductValue: (state) => {
      state.productValue += 1;
    },
    setTotalOrders: (state, action) => {
      state.totalOrders = action.payload;
    },
  },
});

export const { incrementProductValue, setTotalOrders } = dashboardSlice.actions;
export const selectProductValue = (state) => state.dashboard.productValue;
export const selectTotalOrders = (state) => state.dashboard.totalOrders;
export default dashboardSlice.reducer;
