import React, { useEffect, useState } from "react";
import { BsArrowDownRight, BsArrowUpRight } from "react-icons/bs";
import { Column } from "@ant-design/plots";
import { Table } from "antd";
import brand from "../Images/brand.png";
import expense from "../Images/expense.png";
import delivery_service from "../Images/delivery_service.png";
import lead_time from "../Images/lead_time.png";
import aggregate from "../Images/aggregate.png";
import settings from "../Images/settings.png";
import easy_installation from "../Images/easy_installation.png";
import invoice from "../Images/invoice.png";
import { useDispatch, useSelector } from "react-redux";
import { setTotalOrders, selectTotalOrders } from "../Slice/dashboardSlice";
import { setProductList, selectProductList } from "../Slice/productSlice";
import {
  setInstallationOrders,
  selectTotalInstallation,
} from "../Slice/installationSlice";
import axios from "axios";
import useBrands from "../hooks/useBrands";
const columns = [
  {
    title: "SNo",
    dataIndex: "key",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Product",
    dataIndex: "product",
  },
  {
    title: "Status",
    dataIndex: "staus",
  },
];

const data1 = [];
for (let i = 0; i < 46; i++) {
  data1.push({
    key: i,
    name: `Edward King ${i}`,
    product: 32,
    staus: `London, Park Lane no. ${i}`,
  });
}

const Dashboard = () => {
  const dispatch = useDispatch();
  const totalOrders = useSelector(selectTotalOrders);
  const productList = useSelector(selectProductList);
  const { totalBrands, brands} = useBrands();
  const [refresh, setRefresh] = useState(true);
  const installationOrders = useSelector(selectTotalInstallation); // Add this line
  console.log(21, installationOrders);
  console.log(22, brands);

  useEffect(() => {
    // Fetch total orders from your API
    axios
      .get("https://allinonetables.com/api/order/get")
      .then((res) => {
        const totalOrdersCount = res.data.data;
        dispatch(setTotalOrders(totalOrdersCount.length));
      })
      .catch((err) => {
        console.error(err);
      });

    // Fetch product list from your API
    axios
      .get("https://allinonetables.com/api/product/getAll")
      .then((res) => {
        const products = res.data.get;
        // console.log(69, "API Response:", products.length);
        dispatch(setProductList(products.length));
      })
      .catch((err) => {
        console.error(err);
      });

    // Fetch installation orders from your API
    axios
      .get("https://allinonetables.com/api/InstallationOrder/get")
      .then((res) => {
        const installationOrdersData = res.data.data;
        console.log(100, "API Response:", installationOrdersData.length);
        dispatch(setInstallationOrders(installationOrdersData.length));
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch, refresh]);

  const data = [
    {
      type: "Jan",
      sales: 38,
    },
    {
      type: "Feb",
      sales: 52,
    },
    {
      type: "Mar",
      sales: 61,
    },
    {
      type: "Apr",
      sales: 145,
    },
    {
      type: "May",
      sales: 48,
    },
    {
      type: "Jun",
      sales: 38,
    },
    {
      type: "July",
      sales: 38,
    },
    {
      type: "Aug",
      sales: 38,
    },
    {
      type: "Sept",
      sales: 38,
    },
    {
      type: "Oct",
      sales: 38,
    },
    {
      type: "Nov",
      sales: 38,
    },
    {
      type: "Dec",
      sales: 38,
    },
  ];
  const config = {
    data,
    xField: "type",
    yField: "sales",
    color: ({ type }) => {
      return "#e67929";
    },
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 1,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: "Month",
      },
      sales: {
        alias: "Income",
      },
    },
  };
  return (
    <div>
      <h3 className="mb-4 title">Dashboard</h3>
      <div className="d-flex flex-wrap justify-content-between align-items-center gap-3 py-2 ">
        <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white shadow py-3 px-1 px-md-3 mb-2 mb-md-5 bg-body-tertiary rounded">
          <div className="">
            <p className="desc"> Product Orders </p>
            <h2 className="mb-0 sub-title"> {totalOrders}</h2>
          </div>
          <div className="d-flex flex-column align-items-end">
            <img
              className="rotate-icon"
              style={{ width: "5rem" }}
              src={delivery_service}
              alt=""
            ></img>
          </div>
        </div>
        {}
        <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white shadow py-3 px-1 px-md-3 mb-2 mb-md-5 bg-body-tertiary rounded">
          <div className="">
            <p className="desc "> Installation Orders</p>
            <h2 className="mb-0 sub-title">{installationOrders}</h2>
          </div>
          <div className="d-flex flex-column align-items-end">
            <img
              className="rotate-icon"
              style={{ width: "5rem" }}
              src={settings}
              alt=""
            ></img>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-end flex-grow-1 bg-white shadow py-3 px-1 px-md-3 mb-2 mb-md-5 bg-body-tertiary rounded">
          <div>
            <p className="desc"> Total Brands</p>
            <h2 className="mb-0 sub-title"> {totalBrands} </h2>
          </div>
          <div className="d-flex flex-column align-items-end">
            <img
              className="rotate-icon"
              style={{ width: "5rem" }}
              src={brand}
              alt=""
            ></img>
          </div>
        </div>
      </div>

      <div className="d-flex flex-wrap justify-content-between align-items-center gap-3 py-2">
        {}

        {}

        {}
      </div>

      <div className="mt-4">
        <h3 className="mb-5 title">Income Statics</h3>
        <div>
          <Column {...config} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
