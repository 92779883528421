import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as AntTabs } from 'antd';
import './style.css'

// Define your components for the tab contents
const SingleProductUpload = () => <div>Single Product Upload Content</div>;
const BulkProductUpload = () => <div>Bulk Product Upload Content</div>;

const Tabs = ({ items, onChange, className, size }) => {
  return (
    <div className='tabs-container'>
        <AntTabs className={className} size={size} onChange={onChange} items={items} defaultActiveKey={'2'}/>
    </div>
  );
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      children: PropTypes.node.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
	size: PropTypes.string
};

Tabs.defaultProps = {
    className: "",
		size: 'small',
    onChange: (key) => {
        console.log(key);
    },
    items: [
			{
				key: '1',
				label: 'Single Product Upload',
				children: <SingleProductUpload />, // Use the defined component
			},
			{
				key: '2',
				label: 'Upload Products in Bulk',
				children: <BulkProductUpload />, // Use the defined component
			},
    ],
};

export default Tabs;