import { Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEdit } from "react-icons/fa";
import { type } from "@testing-library/user-event/dist/type";

const rowsPerPage = 2;
const CategoryList = () => {
  const [data, setData] = useState([]);
  const [prodName, setprodName] = useState();
  const [costName, setCostName] = useState();
  const [costId, setCostId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(true);
  const [selectedItem, setSelectedItem] = useState("");
  const [SuperCat, setSuperCat] = useState(false);
  const [Cat, setCat] = useState(false);
  const [SubCat, setSubCat] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState();
  const [AllCat, setAllCat] = useState([]);
  const [AllCatData, setAllCatData] = useState([]);
  const [AllSuperCat, setAllSuperCat] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [mainData, setMainData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [selectedCategoryType, setSelectedCategoryType] = useState("");

  const columns1 = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Main Category",
      selector: (row) => row.name,
      width: "700px",
    },

    {
      name: "Actions",
      selector: (row) => (
        <>
          <button
            className="border-0 bg-transparent"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={(e) => {
              setprodName(row._id);
              handleDeleteRow(row._id);
            }}
          >
            <MdDelete className="fs-4" style={{ color: "#dc3545" }} />{" "}
          </button>
          <Link to={`/admin/MainCatEdit/${row._id}`}>
            <button className="border-0 bg-transparent ">
              <FaEdit className="fs-4" style={{ color: "#198754" }} />
            </button>
          </Link>
        </>
      ),
    },
  ];

  const columns2 = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Category",
      selector: (row) => row.name,
      width: "370px",
    },
    {
      name: "Main Category",
      selector: (row) => row.parentName,
      width: "370px",
    },

    {
      name: "Actions",
      selector: (row) => (
        <>
          <button
            className="border-0 bg-transparent"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={(e) => {
              setprodName(row._id);
              handleDeleteRow(row._id);
            }}
          >
            <MdDelete className="fs-4" style={{ color: "#dc3545" }} />{" "}
          </button>
          <Link to={`/admin/CatEdit/${row._id}`}>
            <button className="border-0 bg-transparent ">
              <FaEdit className="fs-4" style={{ color: "#198754" }} />
            </button>
          </Link>
        </>
      ),
    },
  ];

  const columns3 = [
    {
      name: "Sr. No.",
      selector: (row, index) => index + 1,
      width: "80px",
    },
    {
      name: "Sub Category",
      selector: (row) => row.name,
      width: "370px",
    },
    {
      name: " Category",
      selector: (row) => row.parentName,
      width: "370px",
    },

    {
      name: "Actions",
      selector: (row) => (
        <>
          <button
            className="border-0 bg-transparent"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={(e) => {
              setprodName(row._id);
              handleDeleteRow(row._id);
            }}
          >
            <MdDelete className="fs-4" style={{ color: "#dc3545" }} />{" "}
          </button>
          <Link to={`/admin/SubCatEdit/${row._id}`}>
            <button className="border-0 bg-transparent ">
              <FaEdit className="fs-4" style={{ color: "#198754" }} />
            </button>
          </Link>
        </>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "40px",
        backgroundColor: "white",
        marginTop: "7px",
        boxShadow: " 0px 2px 2px #ced4da",
        transition: "background-color 0.3s",
        "&:hover": {
          backgroundColor: "#f0f0f0",
          cursor: "pointer",
        },
      },
    },

    headCells: {
      style: {
        backgroundColor: "rgb(230, 121, 41)",
        fontSize: "15px",
        color: "white",
        fontWeight: "600",
        boxShadow: "0 2px 2px 0 #ced4da, 0 2px 4px 0 rgba(0, 0, 0, 0.19)",
      },
    },

    cells: {
      style: {},
    },
  };

  useEffect(() => {
    fetchData(selectedCategoryType);
    console.log("selectedCategoryType:", selectedCategoryType);
  }, [selectedCategoryType, refresh]);

  const fetchData = (type) => {
    axios
      .get("https://allinonetables.com/api/category/get")
      .then((res) => {
        const response = res.data.categories;

        if (type === "main") {
          const mainFilter = response.filter(
            (item) => item.Type === "Super_Cat"
          );
          setMainData(mainFilter);
          console.log(144, mainFilter);
        }
        if (type === "cat") {
          const catFilter = response.filter((item) => item.Type === "Cat");
          setCatData(catFilter);
          console.log(151, catFilter);
        }
        if (type === "subcat") {
          const subCatFilter = response.filter(
            (item) => item.Type === "Sub_cat"
          );
          setSubCatData(subCatFilter);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteRow = (id) => {
    console.log(126, id);
    axios
      .delete(`https://allinonetables.com/api/category/delete/${id}`)
      .then((response) => {
        console.log(230, response);
        setRefresh(!refresh);
      })
      .catch(toast.success("Deleted successfully !"));
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentPageData = data.slice(startIndex, endIndex);

  return (
    <>
      <h3 className="mb-4 title ">Category List</h3>

      <select
        className="form-select fw-medium form-select mb-3"
        style={{ width: "17rem", backgroundColor: "#dce1e5", color: "black" }}
        aria-label="Large select example"
        onChange={(e) => {
          if (e.target.value === "Main Category") {
            setSelectedCategoryType("main");
            fetchData("main");
          } else if (e.target.value === "Category") {
            setSelectedCategoryType("cat");
            fetchData("cat");
          } else if (e.target.value === "Sub Category") {
            setSelectedCategoryType("subcat");
            fetchData("subcat");
          }
        }}
      >
        <option
          style={{ background: "#f7f6ed", color: "black" }}
          className="chooseOption"
          value=""
        >
          Category List
        </option>
        <option
          style={{ background: "#f7f6ed", color: "black", textAlign: "left" }}
          className="chooseOption"
          value="Main Category"
        >
          Main Category
        </option>
        <option
          style={{ background: "#f7f6ed", color: "black", textAlign: "left" }}
          className="chooseOption"
          value="Category"
        >
          {" "}
          Category
        </option>
        <option
          style={{ background: "#f7f6ed", color: "black", textAlign: "left" }}
          className="chooseOption"
          value="Sub Category"
        >
          Sub Category
        </option>
      </select>

      {selectedCategoryType === "main" && (
        <DataTable
          columns={columns1}
          data={mainData}
          // data={[...currentPageData, ...mainData]}
          customStyles={customStyles}
        />
      )}

      {selectedCategoryType === "cat" && (
        <DataTable
          columns={columns2}
          data={catData}
          customStyles={customStyles}
        />
      )}

      {selectedCategoryType === "subcat" && (
        <DataTable
          columns={columns3}
          data={subCatData}
          customStyles={customStyles}
        />
      )}

      {}
    </>
  );
};

export default CategoryList;
