import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const BannerSchema = Yup.object().shape({
  mainText: Yup.string().required("Main text is required"),
});

function Banner() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [file, setFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [bannerData, setBannerData] = useState({
    mainText: "",
    subText: "",
    buttonText: "",
    buttonNavigationUrl:"",
    image: "",
  });

  useEffect(() => {
    if (id) {
      axios
        .get(`https://allinonetables.com/api/banner/get/${id}`)
        .then((res) => {
          const response = res.data[0];
          debugger
          setBannerData({
            mainText: response.mainText,
            subText: response.subText,
            image: response.image,
            buttonText: response.buttonText,
            buttonNavigationUrl: response.buttonNavigationUrl
          });
          setSelectedImage(response.image);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setFile(file);
    } else {
      setFile(null);
    }
  };

  const handleUpload = (bannerId) => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      axios
        .put(`https://allinonetables.com/api/banner/files/${bannerId}`, formData)
        .then(() => {
          toast.success("Image uploaded");
          navigate("/admin/BannerList");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const saveData = (values, { setSubmitting }) => {
    const dataToSend = { ...values, image: bannerData.image };
    axios
      .post("https://allinonetables.com/api/banner/create", dataToSend)
      .then((res) => {
        const bannerId = res.data.data._id;
        handleUpload(bannerId);
        toast.success("Successfully Added!");
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  };

  const updateFormData = (values, { setSubmitting }) => {
    axios
      .patch(`https://allinonetables.com/api/banner/${id}`, values)
      .then(() => {
        handleUpload(id);
        toast.success("Successfully Updated!");
        setSubmitting(false);
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
      });
  };

  return (
    <>
      <h3 className="mb-4 title">Banner</h3>
      <Formik
        initialValues={bannerData}
        enableReinitialize={true}
        validationSchema={BannerSchema}
        onSubmit={id ? updateFormData : saveData}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form
            className="row g-3 pt-3 flex flex-col shadow p-3 mb-5"
            style={{ padding: 20, backgroundColor: "#f7f6ed" }}
          >
            <div className="relative bg-gray-200 flex justify-center items-center">
              {selectedImage && (
                <>
                  <img
                    src={selectedImage || values.image}
                    alt="Banner Preview"
                    className="w-full h-auto object-cover"
                  />
                  <div className="absolute inset-0 flex flex-col gap-2 justify-center items-start bg-black bg-opacity-50 text-white p-4">
                    <h2 className="text-5xl ms-3 font-bold">{bannerData.mainText || values.mainText}</h2>
                    <p className="text-lg ms-3">{bannerData.subText || values.subText}</p>
                    {(bannerData.buttonText || values.buttonText) && (
                      <a
                        href={`https://allinonetables.com/${bannerData.buttonNavigationUrl || values.buttonNavigationUrl}`}
                        className="mt-4 ms-3 inline-block px-6 py-2 bg-[#e67929] text-white font-semibold rounded hover:bg-[#ec9a5f] transition"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {bannerData.buttonText || values.buttonText}
                      </a>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-6 gap-3 d-flex">
              <div className="col-md-12">
                <label htmlFor="mainText" className="form-label txt-input">
                  Main Text <span className="text-danger">*</span>
                </label>
                <Field
                  type="text"
                  name="mainText"
                  className="form-control"
                  placeholder="Enter main text..."
                />
                <ErrorMessage
                  name="mainText"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="subText" className="form-label txt-input">
                  Sub Text <span className="text-danger">*</span>
                </label>
                <Field
                  type="text"
                  name="subText"
                  className="form-control"
                  placeholder="Enter sub text..."
                />
                <ErrorMessage
                  name="subText"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
            <div className="col-lg-6 gap-3 d-flex">
              <div className="col-md-12">
                <label htmlFor="buttonText" className="form-label txt-input">
                  Banner Navigation Button Text <span className="text-danger">*</span>
                </label>
                <Field
                  type="text"
                  name="buttonText"
                  className="form-control"
                  placeholder="Enter Button text..."
                />
                <ErrorMessage
                  name="buttonText"
                  component="div"
                  className="text-danger"
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="buttonNavigationUrl" className="form-label txt-input">
                  Banner Navigation Url <span className="text-danger">*</span>
                </label>
                <Field
                  type="text"
                  name="buttonNavigationUrl"
                  className="form-control"
                  placeholder="Enter Button Navigation Url..."
                />
                <ErrorMessage
                  name="buttonNavigationUrl"
                  component="div"
                  className="text-danger"
                />
              </div>
            </div>
              <div className="col-md-6">
                <label htmlFor="formFile" className="form-label txt-input">
                {id ? "Update" : "Upload"} Image <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  accept="image/*"
                  onChange={(e) => {
                    handleImageChange(e);
                    setFieldValue("image", e.target.files[0]);
                  }}
                />
              </div>
              <div className="col-md-9" style={{ marginTop: 20 }}>
                <button
                  type="submit"
                  className="btn btn-primary fw-bold shadow border-0"
                  style={{ backgroundColor: "#e67929", width: 100, height: 40 }}
                  disabled={isSubmitting}
                >
                  {id ? "Update" : "Submit"}
                </button>
              </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default Banner;
