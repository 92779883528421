import React from 'react';
import { Modal, Button } from 'antd';

const CustomModal = ({ 
  open, 
  title, 
  onOk, 
  onCancel, 
  children 
}) => {
  return (
    <Modal
      open={open}
      title={title}
      onOk={onOk}
      onCancel={onCancel}>
      {children}
    </Modal>
  );
};

export default CustomModal;
