import { List } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ColourData, menuItemsColor } from "../../pages/ColourData";
import useCategories from "../../hooks/useCategories";
import SearchableDropdown from "../common/SearchableDropdown";
import useBrands from "../../hooks/useBrands";

const SingleProductUpload = () => {
  const navigate = useNavigate();
  const {
    categories,
    superCategories,
    getCategoryById,
    subCategories,
    simpleCategories,
    loading,
    error,
  } = useCategories();
  const [selectedColor, setSelectedColor] = useState("");
  const { brands } = useBrands()

  const { id } = useParams();
  const [AllBrands, setAllBrands] = useState([]);
  const [ShowBulk, setSHowBulk] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [data, setData] = useState({
    name: "",
    short_desc: "",
    color_name: "",
    long_desc: "",
    additional_info: "",
    ship_policy: "",
    price: "",
    sale_price: "",
    sku: "",
    installation_method: "",
    thickness: "",
    wear_layer_thickness: "",
    width: "",
    length: "",
    review: "",
    ratings: "",
    stock: "",
    BestSeller: "",
    newProduct: "",
    IsmostViewed: "",
    isDiscount: "",
    featured: "",
    sold: "",
    SuperCatID: "",
    SuperCatName: "",
    CatID: "",
    CatName: "",
    SubCatID: "",
    SubCatName: "",
    BrandID: "",
    BrandName: "",
    CollectionName: "",
    BoxPrice: "",
    BoxCoverage: "",
    isAddon: "",
    productPictures: "",
    color: "",
    createdBy: "",
    updatedAt: "",
    Manufacture: "",
    Application: "",
    Product_Type: "",
    Commercial_Warranty: "",
    Certifation: "",
    Core_Type_Detail: "",
    Years: "",
    Edge_Type: "",
    Coverage_sqft: "",
    length_cm: "",
    Format_in: "",
    Manufacturer_color: "",
    length_in_fraction: "",
    Shape: "",
    Recidential_warranty: "",
    Underpad_attached: "",
    DescriptionP1: "",
    DescriptionP2: "",
    DescriptionP3: "",
    allow_Delivery: "",
    thickness_Inch: "",
    structure: "",
    surface_Layer: "",
    gloss_Level: "",
    variation_Level: "",
    suitable_Location: "",
    sq_ft_carton: "",
    sq_m_carton: "",
    VolumeML: "",
    widthlsb: "",
    widthinch: "",
    lengthinch: "",
    isClearence: "",
    species: "",
    Finish: "",
    Surface_Texture: "",
    AC_Durability_Rating: "",
    Material: "",
    Onces: "",
    Density: "",
    Look: "",
    VolumeL: "",
    Vapor_Barrier: "",
    Height: "",
    Water_Resistant: "",
    WeightLB: "",
    PEI_Rating: "",
    VolumeML: "",
  });
  const [productPictures, setproductPictures] = useState([]);
  const [formValue, setFormValue] = useState(data);
  const [errors, setErrors] = useState({});
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleInput = (e) => {
    const newObj = { ...formValue, [e.target.name]: e.target.value };
    setFormValue(newObj);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(123, data);
    setErrors(Validation(data));
    const err = Validation(data);
    setErrors(true);
    console.log(89, err);
    if (Object.keys(err).length == 0) {
      {
        axios
          .post("https://allinonetables.com/api/product/create", data)
          .then((response) => {
            const products = response.data;
            console.log(77, products.product._id);
            uploadProductPic(products.product._id);
            toast.success("Form Submitted !");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setErrors(err);
      console.log(err);
      toast.warn("Please fill all feilds.");
    }
  };

  const uploadProductPic = (id) => {
    const formData = new FormData();
    productPictures.forEach((file, index) => {
      formData.append(`productPictures`, file);
    });
    axios
      .put(`https://allinonetables.com/api/product/files/${id}`, formData)
      .then((res) => {
        console.log("Files", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Validation = (values) => {
    const errors = {};
    const regex =
      "^[a-zA-Z0-9]+(?:.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:.[a-zA-Z0-9]+)*$";
    if (!values.name) {
      errors.name = "Please enter product name. ";
    }

    if (!values.sku) {
      errors.sku = " Feild cannot be blank.";
    }

    if (!values.short_desc) {
      errors.short_desc = "Description is required.";
    }

    if (!values.DescriptionP1) {
      errors.DescriptionP1 = "Description P1 is required.";
    }

    if (!values.DescriptionP2) {
      errors.DescriptionP2 = "Description P2 is required.";
    }
    if (!values.DescriptionP3) {
      errors.DescriptionP3 = "Description P3 is required.";
    }

    if (!values.price) {
      errors.price = "Please enter price. ";
    }

    if (!values.BoxPrice) {
      errors.BoxPrice = "Please enter box price. ";
    }

    if (!values.BoxCoverage) {
      errors.BoxCoverage = "Feild cannot be blank. ";
    }

    if (!values.sale_price) {
      errors.sale_price = "Feild cannot be blank. ";
    }

    if (!values.stock) {
      errors.stock = "Feild cannot be blank. ";
    }

    if (!values.CollectionName) {
      errors.CollectionName = "Feild cannot be blank. ";
    }

    if (!values.installation_method) {
      errors.installation_method = "Feild cannot be blank. ";
    }

    if (!values.thickness) {
      errors.thickness = "Feild cannot be blank. ";
    }

    if (!values.wear_layer_thickness) {
      errors.wear_layer_thickness = "Feild cannot be blank. ";
    }

    if (!values.width) {
      errors.width = "Feild cannot be blank. ";
    }

    if (!values.length) {
      errors.length = "Feild cannot be blank. ";
    }

    return errors;
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    console.log(300, files);
    setproductPictures([...files]);
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const imagePreviewArray = [];
    for (let i = 0; i < files.length; i++) {
      const productPictures = files[i];
      const objectURL = URL.createObjectURL(productPictures);
      imagePreviewArray.push(objectURL);
    }
    setImagePreviews(imagePreviewArray);
  };

  useEffect(() => {
    axios
      .get(`https://allinonetables.com/api/product/${id}`)
      .then((res) => {
        const response = res.data[0];
        setData({
          name: response.name,
          slug: response.slug,
          short_desc: response.short_desc,
          long_desc: response.long_desc,
          additional_info: response.additional_info,
          ship_policy: response.ship_policy,
          price: response.price,
          sale_price: response.sale_price,
          sku: response.sku,
          color_name: response.color_name,
          CollectionName: response.CollectionName,
          installation_method: response.installation_method,
          thickness: response.thickness,
          wear_layer_thickness: response.wear_layer_thickness,
          width: response.width,
          length: response.length,
          review: response.review,
          ratings: response.ratings,
          stock: response.stock,
          BestSeller: response.BestSeller ? "true" : "false",
          newProduct: response.newProduct ? "true" : "false",
          IsmostViewed: response.IsmostViewed ? "true" : "false",
          isDiscount: response.isDiscount ? "true" : "false",
          featured: response.featured,
          sold: response.sold,
          SuperCatID: response.SuperCatID,
          SuperCatName: response.SuperCatName,
          CatID: response.CatID,
          CatName: response.CatName,
          SubCatID: response.SubCatID,
          SubCatName: response.SubCatName,
          BrandID: response.BrandID,
          BrandName: response.BrandName,
          isAddon: response.isAddon,
          BoxPrice: response.BoxPrice,
          BoxCoverage: response.BoxCoverage,
          color: response.color,
          Manufacture: response.Manufacture,
          Application: response.Application,
          Product_Type: response.Product_Type,
          Commercial_Warranty: response.Commercial_Warranty,
          Certifation: response.Certifation,
          Core_Type_Detail: response.Core_Type_Detail,
          Years: response.Years,
          Edge_Type: response.Edge_Type,
          Coverage_sqft: response.Coverage_sqft,
          length_cm: response.length_cm,
          Format_in: response.Format_in,
          Manufacturer_color: response.Manufacturer_color,
          length_in_fraction: response.length_in_fraction,
          Shape: response.Shape,
          Recidential_warranty: response.Recidential_warranty,
          Underpad_attached: response.Underpad_attached,
          DescriptionP1: response.DescriptionP1,
          DescriptionP2: response.DescriptionP2,
          DescriptionP3: response.DescriptionP3,
          allow_Delivery: response.allow_Delivery,
          thickness_Inch: response.thickness_Inch,
          structure: response.structure,
          surface_Layer: response.surface_Layer,
          gloss_Level: response.gloss_Level,
          variation_Level: response.variation_Level,
          suitable_Location: response.suitable_Location,
          sq_m_carton: response.sq_m_carton,
          sq_ft_carton: response.sq_ft_carton,
          VolumeML: response.VolumeML,
          widthlsb: response.widthlsb,
          widthinch: response.widthinch,
          lengthinch: response.lengthinch,
          isClearence: response.isClearence,
          species: response.species,
          Finish: response.Finish,
          Surface_Texture: response.Surface_Texture,
          AC_Durability_Rating: response.AC_Durability_Rating,
          Material: response.Material,
          Onces: response.Onces,
          Density: response.Density,
          Look: response.Look,
          VolumeL: response.VolumeL,
          Vapor_Barrier: response.Vapor_Barrier,
          Height: response.Height,
          Water_Resistant: response.Water_Resistant,
          WeightLB: response.WeightLB,
          PEI_Rating: response.PEI_Rating,
          VolumeML: response.VolumeML,
        });
        console.log(317, response);
        setSelectedColor(selectedColor);

        setSHowBulk(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, refresh]);

  const handleSelectedColor = (e) => {
    setSelectedColor(e);
    setData({
      ...data,
      color_name: e,
    });
  };

  const updateFormData = (e) => {
    e.preventDefault();
    console.log(data);
    axios
      .put(`https://allinonetables.com/api/product/update/${id}`, data)
      .then((res) => {
        const response = res.data;
        uploadProductPic(response._id);

        console.log(327, response._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form
      class="row g-3 pt-3 shadow p-3 mt-3"
      style={{ padding: 20, backgroundColor: "#f7f6ed" }}
      onSubmit={Validation}
    >
      <div class="col-md-4 ">
        <label for="floatingSelect " class="col-form-label  pt-0 txt-input">
          New Product{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <select
          class="form-select"
          id="floatingSelect"
          aria-label="Floating label select example"
          value={data.newProduct}
          onChange={(e) => {
            let selectedValue = e.target.value;
            if (selectedValue === "true") {
              setData({ ...data, newProduct: true });
              console.log(442, selectedValue);
              console.log(442, data);
            } else if (selectedValue === "false") {
              setData({ ...data, newProduct: false });
              console.log(442, selectedValue);
            } else {
              setData({ ...data, newProduct: null });
            }
          }}
        >
          <option value=""> --Select--</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      </div>

      <div class="col-md-4 ">
        <label for="floatingSelect1" class="col-form-label  pt-0 txt-input">
          Discount{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <select
          class="form-select"
          id="floatingSelect1"
          aria-label="Floating label select example"
          value={data.isDiscount}
          onChange={(e) => {
            let selectedValue = e.target.value;

            if (selectedValue === "true") {
              setData({ ...data, isDiscount: true });
            } else if (selectedValue === "false") {
              setData({ ...data, isDiscount: false });
            } else {
              setData({ ...data, isDiscount: null });
            }
          }}
        >
          <option value=""> --Select--</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      </div>

      <div class="col-md-4">
        <label for="floatingSelect1" class="col-form-label  pt-0 txt-input">
          Best Seller{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <select
          class="form-select"
          id="floatingSelect1"
          aria-label="Floating label select example"
          value={data.BestSeller}
          onChange={(e) => {
            let selectedValue = e.target.value; // Get the selected value

            if (selectedValue === "true") {
              setData({ ...data, BestSeller: true });
            } else if (selectedValue === "false") {
              setData({ ...data, BestSeller: false });
            } else {
              setData({ ...data, BestSeller: null }); // Handle other cases if needed
            }
          }}
        >
          <option value=""> --Select--</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
        {/* {errors.name && data.BestSeller ===null ? (
			<p
					className="text-danger"
					style={{ fontSize: "14px" }}
			>
					{errors.BestSeller}
			</p>
			) : (
			""
			)} */}
      </div>

      <div class="col-md-4">
        <label for="floatingSelect1" class="col-form-label  pt-0 txt-input">
          Most Viewed{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <select
          class="form-select"
          id="floatingSelect1"
          aria-label="Floating label select example"
          value={data.IsmostViewed}
          onChange={(e) => {
            let selectedValue = e.target.value;
            if (selectedValue === "true") {
              setData({ ...data, IsmostViewed: true });
            } else if (selectedValue === "false") {
              setData({ ...data, IsmostViewed: false });
            } else {
              setData({ ...data, IsmostViewed: null });
            }
          }}
        >
          <option value=""> --Select--</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
        {}
      </div>

      <div class="col-md-4">
        <label for="floatingSelect1" class="col-form-label  pt-0 txt-input">
          Clearence{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <select
          class="form-select"
          id="floatingSelect1"
          aria-label="Floating label select example"
          value={data.isClearence}
          onChange={(e) => {
            let selectedValue = e.target.value;
            if (selectedValue === "true") {
              setData({ ...data, isClearence: true });
            } else if (selectedValue === "false") {
              setData({ ...data, isClearence: false });
            } else {
              setData({ ...data, isClearence: null });
            }
          }}
        >
          <option value=""> -- Select --</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      </div>

      <div class="col-md-4">
        <label for="floatingSelect1" class="col-form-label  pt-0 txt-input">
          Water Resistant{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <select
          class="form-select"
          id="floatingSelect1"
          aria-label="Floating label select example"
          value={data.Water_Resistant}
          onChange={(e) => {
            let selectedValue = e.target.value;
            if (selectedValue === "true") {
              setData({ ...data, Water_Resistant: true });
            } else if (selectedValue === "false") {
              setData({ ...data, Water_Resistant: false });
            } else {
              setData({ ...data, Water_Resistant: null });
            }
          }}
        >
          <option value=""> --Select--</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      </div>

      <div class="col-md-4">
        <label for="inputEmail4" class="form-label txt-input">
          Product Name{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.name}
          id="inputEmail4"
          onChange={(e) => {
            setData({ ...data, name: e.target.value });
            handleInput(e);
          }}
        />
        {errors.name && data.name.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.name}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Installation Method{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.installation_method}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, installation_method: e.target.value });
            handleInput(e);
          }}
        />
        {errors.name && data.installation_method.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.installation_method}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Price{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          type="number"
          class="form-control"
          value={data.price}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, price: e.target.value });
            handleInput(e);
          }}
        />
        {errors.name && data.price.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.price}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Sale Price{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          type="number"
          class="form-control"
          id="inputCity"
          value={data.sale_price}
          onChange={(e) => {
            setData({ ...data, sale_price: e.target.value });
            handleInput(e);
          }}
        />
        {errors.name && data.sale_price.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.sale_price}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Stock{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="number"
          class="form-control"
          value={data.stock}
          id="inputZip"
          onChange={(e) => {
            setData({ ...data, stock: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Box Price{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          type="number"
          class="form-control"
          id="inputZip"
          value={data.BoxPrice}
          onChange={(e) => {
            setData({ ...data, BoxPrice: e.target.value });
            handleInput(e);
          }}
        />
        {errors.name && data.BoxPrice.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.BoxPrice}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Box Coverage{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          type="number"
          value={data.BoxCoverage}
          class="form-control"
          id="inputZip"
          onChange={(e) => {
            setData({ ...data, BoxCoverage: e.target.value });
            handleInput(e);
          }}
        />
        {errors.name && data.BoxCoverage.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.BoxCoverage}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Rating{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="number"
          class="form-control"
          id="inputZip"
          value={data.ratings}
          onChange={(e) => {
            setData({ ...data, ratings: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Review{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="number"
          class="form-control"
          id="inputZip"
          value={data.review}
          onChange={(e) => {
            setData({ ...data, review: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Collection Name{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.CollectionName}
          id="inputZip"
          onChange={(e) => {
            setData({ ...data, CollectionName: e.target.value });
            handleInput(e);
          }}
        />

        {errors.name && data.CollectionName.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.CollectionName}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Thickness{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.thickness}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, thickness: e.target.value });
            handleInput(e);
          }}
        />
        {errors.name && data.thickness.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.thickness}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Wear Layer Thickness{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          type="number"
          class="form-control"
          value={data.wear_layer_thickness}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, wear_layer_thickness: e.target.value });
            handleInput(e);
          }}
        />
        {errors.name && data.wear_layer_thickness.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.wear_layer_thickness}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Width{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          type="number"
          class="form-control"
          value={data.width}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, width: e.target.value });
            handleInput(e);
          }}
        />
        {errors.name && data.width.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.width}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Length{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          type="number"
          class="form-control"
          value={data.length}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, length: e.target.value });
            handleInput(e);
          }}
        />
        {errors.name && data.length.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.length}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          SKU{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          type="number"
          class="form-control"
          value={data.sku}
          id="inputZip"
          onChange={(e) => {
            setData({ ...data, sku: e.target.value });
            handleInput(e);
          }}
        />
        {errors.name && data.sku.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.sku}
          </p>
        ) : (
          ""
        )}
      </div>

      <SearchableDropdown
        wrapperClass="col-md-3 text-dark"
        label="Main Category"
        style={{
          width: "200px",
          backgroundColor: "#e7e7e7",
        }}
        options={superCategories.map((item) => ({
          value: item.name,
          label: item.name,
        }))}
        value={data.SuperCatName}
        onChange={(selectedValue) => {
          const id = categories.find((item) => item.name === selectedValue)._id;
          setData({
            ...data,
            SuperCatName: selectedValue,
            SuperCatID: id,
          });
          console.log(932, id);
          getCategoryById(id);
        }}
        error={errors.SuperCatName}
      />

      <SearchableDropdown
        wrapperClass="col-md-3"
        label="Category"
        style={{
          width: "200px",
          backgroundColor: "#e7e7e7",
          color: "black",
        }}
        options={simpleCategories.map((item) => ({
          value: item.name,
          label: item.name,
        }))}
        value={data.CatName}
        onChange={(selectedValue) => {
          const id = simpleCategories.find(
            (item) => item.name === selectedValue
          )._id;
          console.log(963, id);
          setData({ ...data, CatName: selectedValue, CatID: id });
          categories(id);
        }}
        error={errors.CatName}
      />

      <SearchableDropdown
        wrapperClass="col-md-3"
        label="Sub Category"
        style={{
          width: "200px",
          backgroundColor: "#e7e7e7",
          color: "black",
        }}
        options={subCategories.map((item) => ({
          value: item.name,
          label: item.name,
        }))}
        value={data.SubCatName}
        onChange={(selectedValue) => {
          const id = subCategories.find(
            (item) => item.name === selectedValue
          )._id;
          setData({ ...data, SubCatName: selectedValue, SubCatID: id });
        }}
        error={errors.SubCatName}
      />

      <SearchableDropdown
        wrapperClass="col-md-3"
        label="Brand Name"
        style={{
          width: "200px",
          backgroundColor: "#e7e7e7",
          color: "black",
        }}
        options={brands.map((item) => ({
          value: item.brand_name,
          label: item.brand_name,
        }))}
        value={data.BrandName}
        onChange={(selectedValue) => {
          const id = brands.find((item) => item.brand_name === selectedValue)._id;
          setData({ ...data, BrandName: selectedValue, BrandID: id });
        }}
        error={errors.BrandName}
      />

      <div class="col-lg-4">
        <label for="exampleFormControlTextarea1" class="form-label txt-input">
          Short Description{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          value={data.short_desc}
          rows="2"
          onChange={(e) => {
            setData({ ...data, short_desc: e.target.value });
            handleInput(e);
          }}
        ></textarea>
        {errors.name && data.short_desc.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.short_desc}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-4">
        <label for="exampleFormControlTextarea1" class="form-label txt-input">
          Long Description
        </label>
        <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          value={data.long_desc}
          rows="2"
          onChange={(e) => {
            setData({ ...data, long_desc: e.target.value });
            handleInput(e);
          }}
        ></textarea>
      </div>

      <div class="col-4">
        <label for="exampleFormControlTextarea1" class="form-label txt-input">
          Additional info{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <textarea
          class="form-control"
          value={data.additional_info}
          id="exampleFormControlTextarea1"
          rows="2"
          onChange={(e) => {
            setData({ ...data, additional_info: e.target.value });
            handleInput(e);
          }}
        ></textarea>
      </div>

      <div class="col-md-4">
        <label for="formFileMultiple" class="form-label txt-input">
          Upload Pictures / Files{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <input
          class="form-control"
          name="productPictures"
          value={data.productPictures}
          type="file"
          accept="image/png, image/jpeg, video/*"
          id="formFileMultiple"
          multiple
          onChange={(e) => {
            handleFileChange(e);
            console.log(863, productPictures);
            handleImageChange(e);
          }}
        />

        {imagePreviews.map((preview, index) => (
          <img
            key={index}
            src={preview}
            className="mx-1 mt-1"
            alt=""
            style={{ height: "30px" }}
          />
        ))}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Colour{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <br />
        <select
          value={selectedColor}
          onChange={(e) => handleSelectedColor(e.target.value)}
          style={{
            width: "17rem",
            backgroundColor: "#e7e7e7",
            color: "black",
          }}
          className="btn btn-secondary border-0"
        >
          <option style={{ background: "white", color: "black" }}>
            Select Colour
          </option>
          {menuItemsColor.map((item, index) => {
            return (
              <>
                <option
                  key={index}
                  style={{
                    background: "white",
                    color: "black",
                    textAlign: "left",
                  }}
                  class="dropdown-item"
                  value={item.color_name}
                >
                  {item.color_name}
                </option>
              </>
            );
          })}
        </select>
      </div>

      <div class="col-md-4">
        <label for="inputEmail4" class="form-label txt-input">
          Manufacture{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Manufacture}
          id="inputEmail4"
          onChange={(e) => {
            setData({ ...data, Manufacture: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Product Type{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Product_Type}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Product_Type: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Application{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Application}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Application: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Certifation{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          id="inputCity"
          value={data.Certifation}
          onChange={(e) => {
            setData({ ...data, Certifation: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Commercial Warranty{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Commercial_Warranty}
          id="inputZip"
          onChange={(e) => {
            setData({ ...data, Commercial_Warranty: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Years{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="number"
          class="form-control"
          id="inputZip"
          value={data.Years}
          onChange={(e) => {
            setData({ ...data, Years: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Core Type Detail{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          value={data.Core_Type_Detail}
          class="form-control"
          id="inputZip"
          onChange={(e) => {
            setData({ ...data, Core_Type_Detail: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Coverage (sqft){" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="number"
          class="form-control"
          id="inputZip"
          value={data.Coverage_sqft}
          onChange={(e) => {
            setData({ ...data, Coverage_sqft: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Edge Type{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          id="inputZip"
          value={data.Edge_Type}
          onChange={(e) => {
            setData({ ...data, Edge_Type: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Format (in){" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Format_in}
          id="inputZip"
          onChange={(e) => {
            setData({ ...data, Format_in: e.target.value });
            handleInput(e);
          }}
        />

        {}
      </div>

      <div class="col-md-4">
        <label for="inputZip" class="form-label txt-input">
          Length (Cm ){" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.length_cm}
          id="inputZip"
          onChange={(e) => {
            setData({ ...data, length_cm: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Length (in Fraction){" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="number"
          class="form-control"
          value={data.length_in_fraction}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, length_in_fraction: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Recidential Warranty{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Recidential_warranty}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Recidential_warranty: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Shape{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Shape}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Shape: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Underpad Attached{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Underpad_attached}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Underpad_attached: e.target.value });
            handleInput(e);
          }}
        />
        {}
      </div>

      <div class="col-lg-4">
        <label for="exampleFormControlTextarea1" class="form-label txt-input">
          Description P1{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          value={data.DescriptionP1}
          rows="2"
          onChange={(e) => {
            setData({ ...data, DescriptionP1: e.target.value });
            handleInput(e);
          }}
        ></textarea>
        {errors.DescriptionP1 && data.DescriptionP1.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.DescriptionP1}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-lg-4">
        <label for="exampleFormControlTextarea1" class="form-label txt-input">
          Description P2{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          value={data.DescriptionP2}
          rows="2"
          onChange={(e) => {
            setData({ ...data, DescriptionP2: e.target.value });
            handleInput(e);
          }}
        ></textarea>
        {errors.DescriptionP2 && data.DescriptionP2.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.DescriptionP2}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-lg-4">
        <label for="exampleFormControlTextarea1" class="form-label txt-input">
          Description P3{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger">
            *
          </span>
        </label>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          value={data.DescriptionP3}
          rows="2"
          onChange={(e) => {
            setData({ ...data, DescriptionP3: e.target.value });
            handleInput(e);
          }}
        ></textarea>
        {errors.DescriptionP3 && data.DescriptionP3.length <= 0 ? (
          <p className="text-danger" style={{ fontSize: "14px" }}>
            {errors.DescriptionP3}
          </p>
        ) : (
          ""
        )}
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Manufacturer Color{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Manufacturer_color}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Manufacturer_color: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Allow Delivery{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="number"
          class="form-control"
          value={data.allow_Delivery}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, allow_Delivery: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Thickness (inch){" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="number"
          class="form-control"
          value={data.thickness_Inch}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, thickness_Inch: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Structure{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.structure}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, structure: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Surface Layer{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.surface_Layer}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, surface_Layer: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Gloss Level{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.gloss_Level}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, gloss_Level: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Variation Level{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.variation_Level}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, variation_Level: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Suitable Location{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.suitable_Location}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, suitable_Location: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Sq. m. Carton{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="number"
          class="form-control"
          value={data.sq_m_carton}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, sq_m_carton: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Sq. Ft. Carton{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="number"
          class="form-control"
          value={data.sq_ft_carton}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, sq_ft_carton: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Volumn (ml){" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.VolumeML}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, VolumeML: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Shipping Policy{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.ship_policy}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, ship_policy: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Width (LSB){" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.widthlsb}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, widthlsb: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Width (inch){" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.widthinch}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, widthinch: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Length (inch){" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.lengthinch}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, lengthinch: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Species{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.species}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, species: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Finish{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Finish}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Finish: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Surface Texture{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Surface_Texture}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Surface_Texture: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          AC Durability Rating{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.AC_Durability_Rating}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, AC_Durability_Rating: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Approximate (size in){" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Approximate_sizeIN}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Approximate_sizeIN: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Material{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Material}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Material: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Once{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Onces}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Onces: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Density{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Density}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Density: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Look{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Look}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Look: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Volumn (L){" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.VolumeL}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, VolumeL: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Vapor Barrier{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Vapor_Barrier}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Vapor_Barrier: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Height{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.Height}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, Height: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          Weight (LB){" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.WeightLB}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, WeightLB: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-4">
        <label for="inputCity" class="form-label txt-input">
          PEI Rating{" "}
          <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
        </label>
        <input
          type="text"
          class="form-control"
          value={data.PEI_Rating}
          id="inputCity"
          onChange={(e) => {
            setData({ ...data, PEI_Rating: e.target.value });
            handleInput(e);
          }}
        />
      </div>

      <div class="col-md-12 text-center" style={{ marginTop: 40 }}>
        {id ? (
          <>
            <button
              href="/admin/ProductList"
              type=""
              class="btn btn-primary fw-bold shadow border-0 p-2"
              style={{ backgroundColor: "#e67929", width: 120 }}
              onClick={(e) => {
                handleFormSubmit(e);
                updateFormData(e);
              }}
            >
              Update{" "}
            </button>
          </>
        ) : (
          <>
            <button
              type=""
              class="btn btn-primary fw-bold shadow border-0 p-2"
              style={{ backgroundColor: "#e67929", width: 115 }}
              onClick={(e) => {
                handleFormSubmit(e);
              }}
            >
              Submit
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default SingleProductUpload;
