import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { selectBrands, fetchBrands } from '../Slice/brandSlice'

const useBrands = () => {
  const dispatch = useDispatch();
  const brands = useSelector(selectBrands);
  const totalBrands = brands && brands.length
  const loading = useSelector((state) => state.brand.loading);
  const error = useSelector((state) => state.brand.error);

  useEffect(() => {
		dispatch(fetchBrands())
	},[dispatch])

  const getBrandById = (id) => {
    return brands.find(brand => brand._id === id);
  };

  return { brands, totalBrands ,getBrandById, loading, error }
}

export default useBrands;