import { createSlice } from "@reduxjs/toolkit";

const installationSlice = createSlice({
  name: "installation",
  initialState: {
    installationOrders: 0,
  },
  reducers: {
    setInstallationOrders: (state, action) => {
      state.installationOrders = action.payload;
    },
  },
});

export const { setInstallationOrders } = installationSlice.actions;
export const selectTotalInstallation = (state) => state.installation.installationOrders;
export default installationSlice.reducer;
