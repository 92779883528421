import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WerehouseList = () => {
  const cardsPerPage = 20;
  const [data, setData] = useState([]);
  const [brandName, setbrandName] = useState();
  const [prodid, setprodid] = useState();
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
  });

  useEffect(() => {
    axios
      .get("https://allinonetables.com/api/werehouse/getAll")
      .then((res) => {
        if (res.data && Array.isArray(res.data)) {
          // Sort data by 'name' property, assuming 'name' is the field you want to sort by
          const sortedData = res.data.sort((a, b) => {
            // Assuming 'name' is a string
            return a.brandName.localeCompare(b.brandName);
          });
          console.log(38, sortedData);
          setData(sortedData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  const columns = [
    {
      name: "Sr. No.",
      selector: (row, index) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {index + 1}
        </Link>
      ),
      width: "80px",
    },

    {
      name: "Brand Name",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.brandName}
        </Link>
      ),
      width: "280px",
    },
    {
      name: "Warehouse Address",
      selector: (row) => (
        <Link
          to={`/admin/ProductViewPage/${row.slug}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          {row.werehouseAdd}
        </Link>
      ),
      width: "450px",
    },

    {
      name: "Actions",
      selector: (row) => (
        <>
          <button
            className="border-0 bg-transparent"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={(e) => {
              setbrandName(row.brandName);
              setprodid(row._id);
            }}
          >
            <MdDelete className="fs-4" style={{ color: "#dc3545" }} />{" "}
          </button>

          <Link to={`/admin/wherehouse/${row._id}`}>
            <button className="border-0 bg-transparent ">
              <FaEdit className="fs-4" style={{ color: "#198754" }} />
            </button>
          </Link>
        </>
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "50px",
        backgroundColor: "white",
        marginTop: "7px",
        boxShadow: " 0px 2px 2px #ced4da",
        transition: "background-color 0.3s",
        "&:hover": {
          backgroundColor: "#f0f0f0",
          cursor: "pointer",
        },
      },
    },

    headCells: {
      style: {
        backgroundColor: "rgb(230, 121, 41)",
        fontSize: "15px",
        color: "white",
        fontWeight: "600",
        boxShadow: "0 2px 2px 0 #ced4da, 0 2px 4px 0 rgba(0, 0, 0, 0.19)",
      },
    },

    scrollbar: {
      width: "18px",
      track: {
        backgroundColor: "#f5f5f5",
        borderRadius: "8px", // Customize as needed
      },
      thumb: {
        backgroundColor: "#888",
        borderRadius: "8px", // Customize as needed
      },
    },
  };

  const handleDeleteRow = (id) => {
    console.log(139,id);
    axios
      .delete(`https://allinonetables.com/api/werehouse/${id}`)
      .then((response) => {
        console.log(143,response);
        setRefresh(!refresh);
        toast.success("Deleted successfully!");
      })
      .catch((err) => console.log(err));
  };

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  //   const currentCards = Product.slice(indexOfFirstCard, indexOfLastCard);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <h3 className="mb-3 title ">Warehouse List</h3>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Delete Confirmation
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <h6 className="" style={{ fontSize: "18px" }}>
                Are you sure you want to delete{" "}
                <span className="fw-bold">{brandName}</span> item ?
              </h6>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary border-0"
                data-bs-dismiss="modal"
                style={{ backgroundColor: "#dd1e1e" }}
                onClick={() => handleDeleteRow(prodid)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <DataTable columns={columns} data={data} customStyles={customStyles} />

      <nav aria-label="Page navigation example .d-sm-none .d-md-block">
        <ul className="pagination justify-content-end mt-4">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link "
              style={{
                backgroundColor: "transparent",
                color: "black",
                border: "0",
              }}
              aria-label="Previous"
              onClick={() => paginate(currentPage - 1)}
            >
              <span
                className=""
                style={{ fontSize: "25px" }}
                aria-hidden="true"
              >
                &laquo;
              </span>
            </button>
          </li>

          {Array.from({ length: 5 }).map((_, index) => {
            const pageNumber = currentPage + index;
            return (
              <li
                key={index}
                className={`page-item ${
                  currentPage === pageNumber ? "active" : ""
                }`}
              >
                <button
                  className="page-link num-click ms-1 mt-2"
                  style={{
                    borderRadius: 60,
                    backgroundColor:
                      currentPage === pageNumber ? "#e67929" : "#f7f6ed",
                    color: currentPage === pageNumber ? "white" : "black",
                    border: "0",
                  }}
                  onClick={() => handlePageClick(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            );
          })}

          <li className={`page-item ${currentPage === 5 ? "disabled" : ""}`}>
            <button
              className="page-link "
              style={{
                backgroundColor: "transparent",
                color: "black",
                border: 0,
                fontSize: "25px",
              }}
              onClick={() => paginate(currentPage + 1)}
              aria-label="Next"
            >
              &raquo;
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default WerehouseList;
