import React from 'react';
import PropTypes from 'prop-types';
import { useFormik, FormikProvider, Form as FormikForm, Field } from 'formik';

const Form = ({ formName, initialValues, validationSchema, onSubmit, fields, submitButton }) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <FormikForm name={formName}>
        {fields.map((field, index) => (
          <div key={index} className={field.className}>
            <label className='form-label txt-input' htmlFor={field.name}>{field.label}</label>
            <Field
              id={field.name}
              name={field.name}
              type={field.type}
              component={field.component}
              {...field.props}
            />
            {formik.touched[field.name] && formik.errors[field.name] ? (
              <div className="error">{formik.errors[field.name]}</div>
            ) : null}
            {field.renderComponent && field.renderComponent(formik)}
          </div>
        ))}
        {submitButton}
      </FormikForm>
    </FormikProvider>
  );
};

Form.propTypes = {
  formName: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
  validationSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      className: PropTypes.string,
      component: PropTypes.elementType,
      props: PropTypes.object,
    })
  ).isRequired,
  submitButton: PropTypes.node.isRequired,
};

export default Form;
