import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const Werehouse = () => {
  const [refresh, setRefresh] = useState(true);
  const { id } = useParams();
  console.log(13, id);
  const [AllBrands, setAllBrands] = useState([]);
  const [data, setData] = useState({
    brandName: "",
    werehouseAdd: "",
    brandId: "",
  });

  useEffect(() => {
    axios
      .get("https://allinonetables.com/api/brand/getAll")
      .then((res) => {
        const response = res.data;
        console.log(21, response);
        setAllBrands(response);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`https://allinonetables.com/api/werehouse/get/${id}`)
      .then((res) => {
        const response = res.data[0];
        console.log(21, response);
        // setAllBrands(response);
        setData({
          brandName: response?.brandName || "",
          werehouseAdd: response?.werehouseAdd ||"",
          brandId: response?.brandId || "",
        })
      })
      .catch((err) => {
        console.log(err);
      });



  }, [refresh,id]);

  const SaveCAt = (e) => {
    e.preventDefault();
    console.log(66, data);
    axios
      .post("https://allinonetables.com/api/werehouse/create", data)
      .then((res) => {
        const response = res.data;
        console.log(21, response);
        toast.success("Form Submitted !");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateFormData = (e) => {
    e.preventDefault();
    axios
      .put(`https://allinonetables.com/api/werehouse/${id}`, data)
      .then((res) => {
        const response = res.data;
        console.log(72, response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h3 className="mb-4 title "> Warehouse</h3>

      <form
        class="row g-3 pt-3 shadow p-3 mt-4"
        style={{ padding: 20, backgroundColor: "#f7f6ed" }}
      >
        <div class="col-md-6 ">
          <div class="col-md-3">
            <label for="inputCity" class="form-label txt-input">
              Brand Name{" "}
            </label>
            <br />
            <select
              name=""
              id=""
              style={{
                width: "200px",
                backgroundColor: "#e7e7e7",
                color: "black",
              }}
              className="btn btn-secondary border-0"
              onChange={(e) => {
                const selectedValue = e.target.value;
                console.log(65, selectedValue);
                const id = AllBrands.find(
                  (item) => item.brand_name === selectedValue
                )._id;
                setData({ ...data, brandName: selectedValue, brandId: id });
                // console.log("ok");
              }}
            >
              <option style={{ background: "white", color: "black" }} value="">
                -- Select Brand --
              </option>
              {AllBrands.map((item) => {
                return (
                  <>
                    <option
                      style={{
                        background: "white",
                        color: "black",
                        textAlign: "left",
                      }}
                      class="dropdown-item"
                      href="#"
                      value={item.brand_name}
                    >
                      {item.brand_name}
                    </option>
                  </>
                );
              })}
            </select>
          </div>
          <br />
          <label for="inputEmail4" class="form-label txt-input">
            Warehouse Address{" "}
            <span style={{ fontSize: "1.2rem" }} className="text-danger"></span>
          </label>
          <input
            type="text"
            class="form-control"
            id="inputEmail4"
            placeholder="Enter warehouse address.."
            value={data.werehouseAdd}
            onChange={(e) => setData({ ...data, werehouseAdd: e.target.value })}
          />
        </div>
        {
          id ?(<>
        <div class="col-md-12 " style={{ marginTop: 20 }}>
          <button
            type="submit"
            class="btn btn-primary shadow border-0 p-2 fw-bold"
            style={{ backgroundColor: "#e67929", width: 100 }}
            onClick={(e) => {
              updateFormData(e)
            }}
          >
            update
          </button>
        </div>
          
          </>):(<>
        <div class="col-md-12 " style={{ marginTop: 20 }}>
          <button
            type="submit"
            class="btn btn-primary shadow border-0 p-2 fw-bold"
            style={{ backgroundColor: "#e67929", width: 100 }}
            onClick={(e) => {
              SaveCAt(e);
            }}
          >
            Submit
          </button>
        </div>
          
          </>)
        }

      </form>
    </div>
  );
};

export default Werehouse;
