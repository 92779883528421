import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchCategories, selectCategories, selectSuperCategories, selectSubCategories, selectSimpleCategories } from "../Slice/categorySlice";

const useCategories = () => {
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const superCategories = useSelector(selectSuperCategories);
  const subCategories = useSelector(selectSubCategories);
  const simpleCategories = useSelector(selectSimpleCategories);
  const loading = useSelector((state) => state.categories.loading);
  const error = useSelector((state) => state.categories.error);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  const getCategoryById = (id) => {
    return categories.find(category => category._id === id);
  };

  return { categories, superCategories, loading, error, subCategories ,getCategoryById, simpleCategories };
};

export default useCategories;
