import axios from "axios";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
const CostEstimEdit = () => {
  const [maindata, setMainData] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const { id } = useParams();
  console.log(id);
  const [subData, setsubData] = useState([]);
  const [data, setData] = useState({
    name: "",
    parentId: "",
    parentName: "",
    price: "",
    type: "child",
  });

  useEffect(() => {
    axios
      .get("https://allinonetables.com/api/cost/get")
      .then((res) => {
        console.log(104, res.data);
        setMainData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  useEffect(() => {
    axios
      .get(`https://allinonetables.com/api/cost/get/single/${id}`)
      .then((res) => {
        const response = res.data[0];
        data.name = response.name;
        data.parentName = response.parentName;
        data.price = response.price;
        data.parentId = response.parentId;
        console.log(36, response);
        setData({
            ...data,
          name: response.name,
          price: response.price,
          parentName: response.parentName,
          parentId: response.parentId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const updateFormData = (e) => {
    e.preventDefault();
    console.log(55,data);
    axios
      .put(`https://allinonetables.com/api/cost/${id}`, data)
      .then((res) => {
        const response = res.data;
        console.log(63, response);
        setData({
            ...data,
            name: response.name,
            parentName: response.parentName,
            price: response.price,
            parentId: response.parentId,
          });
        toast.success("Updated Successfully !");
        Navigate(`/admin/CostEstimationList`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h3 className="mb-4 title">Edit Cost Estimation </h3>
      <form
        class="row g-3 pt-3 shadow p-3 mt-4"
        style={{ padding: 20, backgroundColor: "#f7f6ed" }}
      >
        <div class="col-lg-12">
          <label for="inputEmail4" class="form-label txt-input">
            Sub Category :
          </label>
          <select
            class="form-select fw-medium form-select mt-3"
            // value={data.parentName}
            style={{
              width: "16rem",
              backgroundColor: "#dce1e5",
              color: "black",
            }}
            aria-label="Large select example"
            onChange={(e) => {
                // handleSelectChange(e);
            }}
          >
            <option selected  style={{ background: "white" }}>
              Select option
            </option>
            {maindata.map((item) => {
              return (
                <>
                  <option key={item._id} value={item._id} style={{ background: "white" }}>
                    {item.name}
                  </option>
                </>
              );
            })}
          </select>
        </div>

        <div class="col-md-5 my-3">
          <label for="inputEmail4" class="form-label txt-input mt-3">
            Name <span className="text-danger"></span>
          </label>
          <input
            type="text"
            class="form-control"
            id="inputEmail4"
            placeholder="Enter sub name.."
            value={data.name}
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
            }}
          />
        </div>
        <div class="col-md-3">
          <label for="inputEmail4" class="form-label txt-input mt-3">
            Price <span className="text-danger"></span>
          </label>
          <input
            type="number"
            class="form-control"
            id="inputEmail4"
            placeholder="Enter price.."
            value={data.price}
            onChange={(e) => {
              setData({ ...data, price: e.target.value });
            }}
          />
        </div>

        <div class="col-md-12 " style={{ marginTop: 20 }}>
          <button
            type="submit"
            class="btn btn-primary shadow border-0 p-2 "
            style={{ backgroundColor: "#e67929", width: 100 }}
            onClick={(e) => updateFormData(e)}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default CostEstimEdit;
