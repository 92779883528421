import axios from "axios";
import AppInstance from "./appInstance";

export default class ApiUtil {
	static async SendAsync(request) {
		try {
			const res = await AppInstance(request);
			return res;
		} catch (error){
			if (axios.isCancel(error)) {
				// Handle cancellation error
				console.log('Request canceled:', error.message);
			  } else {
				// Handle other errors
				console.log('Error in SendAsync:', error);
			  }
			  throw error;
		}
	}
}