import axios from 'axios';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getFileExtension, parseCsvFile, parseXlsxFile } from '../../utils';
import LoadingButton from "../common/LoadingButton";
import { Form } from "../common/formik";

const ProductBulkUpload = () => {
  const [excelData, setExcelData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error('No file selected');
      return;
    }

    const fileType = getFileExtension(file);
    if (!['csv', 'xlsx'].includes(fileType)) {
      toast.error('Please upload a valid .csv or .xlsx file');
      return;
    }
    setLoading(true);
    try {
      let data;
      if (fileType === 'csv') {
        data = await parseCsvFile(file);
      } else if (fileType === 'xlsx') {
        data = await parseXlsxFile(file);
      }
      setExcelData(data);
    } catch (error) {
      console.error('Error:', error);
      setError("Failed to parse file");
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    if (!excelData) {
      alert('Please select an Excel file');
      return;
    }

    setLoading(true);
    setError("");
    try {
      await axios.post("https://allinonetables.com/api/product/bulkUpload", excelData);
      
      setExcelData(null)
      toast.success("Data Saved Successfully...");
    } catch (error) {
      console.error('Error:', error);
      setError("Failed to upload data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {}, [excelData]);

  return (
    <div className="row g-3 pt-3 shadow p-3 mt-4 bg-f7f6ed">
      <Form
        formName="bulkUploadForm"
        initialValues={{ fileInput: null }}
        // validationSchema={schema}
        onSubmit={handleUpload}
        fields={[
          {
            name: 'fileInput',
            type: 'file',
            label: 'Choose File',
            className: 'col-md-6',
            props: {
              className: 'form-control',
              onChange: handleFileChange,
            },
          },
        ]}
        submitButton={
          <LoadingButton
            className="btn bg-e67929 text-white fw-bold shadow border-0 px-4 py-2 my-3"
            loading={loading}
            type="submit">
            Submit
          </LoadingButton>
        } 
      />
    </div>
  );
}

export default ProductBulkUpload;
