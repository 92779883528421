import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiUtil from "../api/ApiUtil";

export const fetchBrands = createAsyncThunk(
  "brand/fetchBrands",
  async () => {
    const response = await ApiUtil.SendAsync({
      url: "/brand/getAll",
      method: "GET"
    });
    return response.data;
  }
);

const brandSlice = createSlice({
  name: "brand",
  initialState: {
    brands: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.loading = false;
        state.brands = action.payload;
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectBrands = (state) => state.brand.brands;

export default brandSlice.reducer;
