import Tabs from "../components/common/Tabs";
import { SingleProductUpload, ProductBulkUpload } from "../components/Product";

const Products = () => {

  const items = [
    {
      key: '1',
      label: 'Single Product Upload',
      children: <SingleProductUpload />,
    },
    {
      key: '2',
      label: 'Bulk Product Upload',
      children: <ProductBulkUpload />,
    },
  ];

  return (
    <>
      <Tabs items={items} size="large"/>
    </>
  );
};

export default Products;
